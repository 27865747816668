import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Card, Row, Col, Statistic, Button, Spin } from "antd";
import { ApplicationState } from "store";
import { fetchSalaryPayMonthStatistics } from "store/workbrench/actions";
import { CID, TDAPP } from "common/constants";
import moment from "moment";
import "./VhGrantManage.scss";

const mapState = ({ commonState, workBenchState }: ApplicationState) => ({
  /** 加载 */
  loading: commonState.loading,
  /** 收入发放统计 */
  salaryStatistics: workBenchState.salaryStatistics
})

interface PropsOwn extends RouteComponentProps {
   /** 公司名称 */
  name: string;
  /** 姓名 */
  realName: string;
}

/** 工作台-发放管理 */
const VhGrantManage = (props: PropsOwn) => {

  const { loading, salaryStatistics } = useSelector(mapState)

  const { history } = props

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchSalaryPayMonthStatistics({
      companyId: CID,
      month: moment().format("YYYY-MM")
    }))
  }, [])

  const manageData: any = [
    { id: 1, name: "本月进行中的发放批次", num: salaryStatistics.processNum },
    { id: 2, name: "本月发放失败笔数", num: salaryStatistics.failNum },
    { id: 3, name: "本月发放失败批次数", num: salaryStatistics.needResendNum },
  ]

  const handleOnMouseEnter = (module: string) => {
    const { realName, name } = props;
    const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
    TDAPP.onEvent("进入工作台模块",`${name}_${realName}`,{"用户名":`${realName}_${timestamp}`,"公司" : `${name}_${timestamp}`,"模块": `${module}_${timestamp}`})
  }

  const handleOnMouseLeave = (module: string) => {
    const { realName, name } = props;
    const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
    TDAPP.onEvent("离开工作台模块",`${name}_${realName}`,{"用户名":`${realName}_${timestamp}`,"公司" : `${name}_${timestamp}`,"模块": `${module}_${timestamp}`})
  }

  return (
    <Card 
      onMouseEnter={ handleOnMouseEnter.bind(VhGrantManage,"发放管理") } onMouseLeave = { handleOnMouseLeave.bind(VhGrantManage,"发放管理") }
    className="grant-manage-card" title="发放管理" bordered={false} extra={(
      <Button type="link" onClick={() => history.push("/paySalaryManager/pay-salary-records")}>
        查看全部
      </Button>
    )}>
      <Spin spinning={loading.showA} tip={loading.text}>
        <Row gutter={[20, 30]}>
          {manageData.map((item: any) => (
            <Col key={item.id} span={8} className="grantItem">
              {/* <Statistic title={item.name} value={item.num} /> */}
              <span className="num">{item.num}</span>
              <span className="name">{item.name}</span>
            </Col>
          ))}
        </Row>
      </Spin>
    </Card>
  )
}

export default withRouter(VhGrantManage)
import { IUser, ICompany, IProvince } from "common/types";

import { VhButtonPermission } from "msd/dist/vh-button";
import { VhMenu } from "msd/dist/vh-sider";

/**
 * 应用类型
 *
 * @export
 * @interface MsApp
 */
export interface MsApp {
  /**
   * appId
   *
   * @type {string}
   * @memberof MsApp
   */
  appId: string;
  /**
   * appCode
   *
   * @type {string}
   * @memberof MsApp
   */
  appCode: string;
  /**
   * 应用名称
   *
   * @type {string}
   * @memberof MsApp
   */
  appName: string;
  /**
   * 应用类型区分
   * 4-平台应用 其他-非平台应用
   *
   * @type {string}
   * @memberof MsApp
   */
  appTypeId?: number;
  /**
   * 应用链接地址
   *
   * @type {string}
   * @memberof MsApp
   */
  appUrl?: string;
  /**
   * 应用文字图标
   *
   * @type {string}
   * @memberof MsApp
   */
  appFontName?: string;
  /**
   * 文字图标颜色
   *
   * @type {string}
   * @memberof MsApp
   */
  appFontColor?: string;
  /**
   * 显示方式 1：内嵌 2：外链
   *
   * @type {("1" | "2")}
   * @memberof MsApp
   */
  displayMode?: "1" | "2";
  /**
   * 是否常用 0：非常用 1：常用
   *
   * @type {("0" | "1")}
   * @memberof MsApp
   */
  settingFlag?: "0" | "1";
  /**
   * 分组 1：右上角 2：其他
   *
   * @type {("1" | "2")}
   * @memberof MsApp
   */
  groupId?: "1" | "2";
}

/**
 * 应用类型
 * @export
 * @interface App
 */
export interface App {
  /**应用id */
  ID: string;
  /**应用名称 */
  appName: string;
  /**应用code */
  appCode: string;
  /**应用组id */
  appGroupID: number;
  /**安装模式 1#默认安装，2#手动安装（审核），3#手动安装（无需审核） */
  setupMode: string;
  /**星级 */
  stars: number;
  /**付费模式 1#免费，2#付费 */
  payMode: number;
  /**应用访问地址 */
  appURL: string;
  /**应用字体名称 */
  appFontName: string;
  /**应用字体颜色 */
  appFontColor: string;
  /**事件通知地址 */
  notifyURL: string;
  /**是否有应用配置 1#是，0#否 */
  appConfigFlag: number;
  /**应用配置地址 */
  appConfigURL: string;
  /**是否有权限配置: 1#是，0#否 */
  permissionConfigFlag: number;
  /**权限配置地址 */
  permissionConfigURL: string;
  /**显示方式： 1#内嵌、2#外联 */
  displayMode: number;
  /**是否有工作台: 1#是，0#否 */
  workbenchFlag: number;
  /**应用代号 */
  appAlias: string;
  /**开发者 */
  developer: string;
  /**产品线 */
  productLine: string;
}

export interface Menu {
  /**id */
  id: string;
  /**分组名称 */
  groupName: string;
  /**分组图标 */
  groupIconName: string;
  /**功能名称 */
  functionName: string;
  /**功能编码 */
  functionCode: string;
  /**功能图标 */
  iconName: string;
  /**访问地址 */
  visitURL: string;
  externalUrl?: string
}

/**
 * action类型枚举
 * @export
 * @enum {number}
 */
export enum AuthorizationActionTypes {
  /**获取用户信息 */
  FETCH_USER_INFO = "@@authorization/FETCH_USER_INFO",
  FETCH_USER_INFO_SUCCESS = "@@authorization/FETCH_USER_INFO_SUCCESS",
  FETCH_USER_INFO_ERROR = "@@authorization/FETCH_USER_INFO_ERROR",

  /**获取应用列表 */
  FETCH_APP_LIST = "@@authorization/FETCH_APP_LIST",
  FETCH_APP_LIST_SUCCESS = "@@authorization/FETCH_APP_LIST_SUCCESS",
  FETCH_APP_LIST_ERROR = "@@authorization/FETCH_APP_LIST_ERROR",

  /**获取用户配置应用列表（个人设置，公司设置等权限） */
  FETCH_CONFIG_APP_LIST = "@@authorization/FETCH_CONFIG_APP_LIST",
  FETCH_CONFIG_APP_LIST_SUCCESS = "@@authorization/FETCH_CONFIG_APP_LIST_SUCCESS",
  FETCH_CONFIG_APP_LIST_ERROR = "@@authorization/FETCH_CONFIG_APP_LIST_ERROR",

  /**获取菜单列表 */
  FETCH_MENU_LIST = "@@authorization/FETCH_MENU_LIST",
  FETCH_MENU_LIST_SUCCESS = "@@authorization/FETCH_MENU_LIST_SUCCESS",
  FETCH_MENU_LIST_ERROR = "@@authorization/FETCH_MENU_LIST_ERROR",

  /**获取公共字典(新版) */
  FETCH_COMMON_NEW_MAP = "@@authorization/FETCH_COMMON_NEW_MAP",
  FETCH_COMMON_NEW_MAP_SUCCESS = "@@authorization/FETCH_COMMON_NEW_MAP_SUCCESS",
  FETCH_COMMON_NEW_MAP_ERROR = "@@authorization/FETCH_COMMON_NEW_MAP_ERROR",

  /**登出操作 */
  FETCH_LOGOUT = "@@authorization/FETCH_LOGOUT",
  FETCH_LOGOUT_SUCCESS = "@@authorization/FETCH_LOGOUT_SUCCESS",
  FETCH_LOGOUT_ERROR = "@@authorization/FETCH_LOGOUT_ERROR",
}

/**
 * Authorization state
 * @export
 * @interface AuthorizationState
 */
export interface AuthorizationState {
  /**用户信息 */
  readonly userInfo: IUser;
  /**公司信息 */
  readonly companyInfo: ICompany;
  /**应用列表 */
  readonly appList: MsApp[];
  readonly configAppList: MsApp[];
  readonly appInfo: MsApp;
  /**菜单列表 */
  readonly menuList?: VhMenu[];
  /**新字典 */
  readonly commonNewMap: any;
  readonly errors?: string;
  /**loading状态 */
  readonly loading: boolean;
}

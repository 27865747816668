import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Store } from "redux";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale-provider/zh_CN";
import { ApplicationState } from "./store";
import "moment/locale/zh-cn";

import { History } from "history";
import MsLayout from "components/ms-layout/MsLayout";

// 组件props声明
interface AppProps {
  store: Store<ApplicationState>;
  history: History;
}

/**
 * 根组件
 * 1、使用Provider提供redux state
 * 2、ConnectedRouter连接redux和router
 * 3、LocaleProvider提供antd中文设置
 * 4、加载Home组件
 *
 * @class App
 * @extends {React.Component<AppProps>}
 */
class App extends React.Component<AppProps> {
  // 渲染
  public render() {
    // 从props中获取store和history
    const { 
      store,
       history 
      } = this.props;

    // 全局配置
    const config = {
      autoInsertSpaceInButton: false
    };

    return (
      <Provider store={store}>
        <ConnectedRouter history={ history }>
            <ConfigProvider locale = { zhCN } {...config}>
                <Switch>
                  {/**路由到首页 */}
                  {/* <Route path="/document-managerment" component={ MsLayout } /> */}
                  <Route path="/" component={ MsLayout } />
                  <Redirect to="/" />
                </Switch>
            </ConfigProvider>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;

import Tools from "utils/tools";

import { IMapType, ModalPositiion } from "./types";

// import { VhTools } from "msd";

export const env = process.env.REACT_APP_ENV

export const LOGIN_URL = `${env === "pro" ? "https" : "http"}://${env !== "pro" ? (env === "test" ? "dv" : (env === "pre" ? "test" : "dev")) : ""}login.viphrm.com${env === "dev" || env === "local" ? ":8088" : ""}`;

export const HOME_PAGE = `http://${env !== "pro" ? env : ""}moss.viphrm.com`;

export const ERROR_MESSAGE = "系统维护中，请联系管理员。";

export const LOADING_MESSAGE = "正在查询，请稍后...";

export const WEIZHIPAY_URL = `${env === "pro" ? "https" : "http"}://${env !== "pro" ? env : ""}weizhipay.viphrm.com`;

// export const APPID = 1602;

// export const USER_CENTER = "30000";

// export const APP_CODE = "30000";

export const showSmartHRApplist = ["30001", "30002"]

// export const TOKEN = Tools.getCookie("ms_member_token") || "209157.eyJhbGciOiJIUzI1NiJ9.eyJhcHBpZCI6IjEzMDAxIiwiZXhwIjoxNjU1ODA0OTM4LCJ1c2VyaWQiOiIyMDkxNTcifQ.RFxBnPcBuS2felH976acTWE_s1DNE29osYp-AfAu0FM";

// // export const CID = Tools.getCookie("ms_cid") || "288572";
// export const CID = Tools.getCookie("ms_cid") || "207920455";

// 首次进来的时候，url后面带token和cid了，把token和cid设置到当前域名cookie里面，页面切换路由刷新的时候，此时url为路由地址，从cookie里面获取token和cid
if (Tools.getUrlParam("token")) {
  const date = new Date();
  // date.setTime(date.getTime() + 1);
  date.setDate(date.getDate() + 3)
  Tools.setCookie("ms_member_token", Tools.getUrlParam("token"), date, "smarthr.cn")
  Tools.setCookie("ms_cid", Tools.getUrlParam("cid"), date, "smarthr.cn")

  
  // Tools.setCookie("ms_member_token", Tools.getUrlParam("token"), date, "viphrm.com")
  // Tools.setCookie("ms_cid", Tools.getUrlParam("cid"), date, "viphrm.com")
}

export const CID = Tools.getCookie("ms_cid") || Tools.getUrlParam("cid") || "202442844";
export const TOKEN = Tools.getCookie("ms_member_token") || Tools.getUrlParam("token") || "102441839.eyJhbGciOiJIUzI1NiJ9.eyJhcHBpZCI6IjEzMDAxIiwiZXhwIjoxNjYyNjIwNTIyLCJ1c2VyaWQiOiIxMDI0NDE4MzkifQ.vDa7-SziWLvvCgTqyR40OvjTcSL7O65ymhipWJm1kCk";

/**pdf预览 */
export const pdfUrl = `${env === "dev" ? "http://192.168.1.181:9077" : (`https://${env === "pro" ? "" : env}uploadv2.viphrm.com`)}/filemanager/viewer.html?file=`;

export const preAliUrl = env === "dev" ? "http://192.168.1.181:9077" : env === "pre" ? "https://preuploadv2.viphrm.com" : "https://uploadv2.viphrm.com";

/**excel下载 */
export const excelUrl = env === "pro" ? "https://salaryslipmanager.viphrm.com" : env === "pre" ? "http://presalarymanager.viphrm.com" : "http://devsalarymanager.viphrm.com"

/**附件路径 */
export const aliUrl = preAliUrl + "/filemanager/preview/?path=";

/** 接口前置路径 */
export const defaultUrl = "salary/calculate/platform"
// export const defaultUrl = "salary/calculate/enterprise"

/**延迟时长 */
export const delayTimer = 1000;

export const TAX_CRITIAL_MAX_VALUE_LIMIT = 999999999;

export const acceptPic = "jpg、jpeg、png";
export const ONE_M = 1048576;
export const limitSize = 1;
export const fileUploadUrl = "/filemanager/picupload.jsx";


/**外链来源对应的AppCode */
export const SOURCE_APPCODE = Tools.getUrlParam("sourceAppCode") || "";

/**批量导入数量上限 */
export const maxImportDataRows = 500;

/**正则 */
export const REGEXP = {
  /**金额 */
  salary: "^[0-9]+$|^[0-9]+[.][0-9]{1,2}$"
};


/**通用modal尺寸 */
export const MODAL_POSITION: ModalPositiion = {
  top: 50,
  height: document.body.offsetHeight - 100
}

export let SALARY_CONFIG_MANAGER_URL = ""

export let PAY_SALARY_MANAGER_URL = ""

switch (env) {
  case "pro":
    SALARY_CONFIG_MANAGER_URL = "salaryslipmanager.viphrm.com";
    PAY_SALARY_MANAGER_URL = "salaryweizhi.viphrm.com";
    break
  case "pre":
    SALARY_CONFIG_MANAGER_URL = "presalarymanager.viphrm.com";
    PAY_SALARY_MANAGER_URL = "testsalaryweizhi.viphrm.com";
    break;
  case "dev":
    SALARY_CONFIG_MANAGER_URL = "devsalarymanager.viphrm.com";
    PAY_SALARY_MANAGER_URL = "devsalaryweizhi.viphrm.com";
    break
  case "local":
    SALARY_CONFIG_MANAGER_URL = "localhost:30001";
    PAY_SALARY_MANAGER_URL = "localhost:30002";
    break
}

export function getPreRouterUrl(code: string) {
  switch (code) {
    case "30001":
      // 薪酬管理企业端增加路径前缀
      return "/salaryConfigManager"
    case "30002":
      // 收入发放企业端增加路径前缀
      return "/paySalaryManager"
    default:
      return ""
  }
}

export const homeUrl = env === "pro" ? "https://home.viphrm.com" : env === "pre" ? "http://prehome.viphrm.com" : "http://devhome.viphrm.com";

export const TDAPP = (window as any).TDAPP;
import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { MapType } from "common/types";
import { Icon, Button } from "antd";
import VhBread from "./vh-bread/VhBread";

interface PropsOwn {
  routeBreadName: MapType;            // 父组件传入的路径对应的名称
  style?: any,
}

type AllProps = PropsOwn & RouteComponentProps;

export default withRouter(
class VhBreadNav extends Component<AllProps> {

  /**
   * 右边的返回按钮
   */
  public onGoBack = (pathname: string) => {
    this.props.history.push({
      pathname,
      state: this.props.location.state
    })
  }

  /**
   * 面包屑导航，如果路径在routeName中匹配不到则跳转。
   * 逻辑：
   * 1. 遍历routeBreadName找到匹配的内容，可能有多个，但只选取最后一条匹配的
   * 2. 根据"/"拆分路径给arr，
   * 3. 再将arr变成["/a", "/a/b", "/a/b/c"]的形式
   * 4. 遍历arr给paths。
   * 5. 如果长度大于1，显示返回按钮
   */
  public render() {
    const { location, routeBreadName, match, style = {} } = this.props;
    const url = match.url;
    const path = location.pathname;
    const matchArr: string[] = Object.keys(routeBreadName).filter((ele: string) => path.startsWith(`${url}${ele}`));
    const matchPath: string = matchArr.length ? matchArr[matchArr.length - 1] : "";
    const prefix: string | undefined = Object.keys(routeBreadName).find((ele: string) => ele.indexOf(matchPath) > 0);
    let navDom: JSX.Element | null = null;
    let backDom: JSX.Element | null = null;
    if (matchPath) {
      const arr = matchPath.split("/").filter((ele: any) => !!ele);
      
      const paths = arr.map((ele: any) => matchPath.substr(0, matchPath.indexOf(ele) + ele.length))
                       .map((ele: string, i: number) => (
                        {
                          breadcrumbName: routeBreadName[ele], 
                          path: i === arr.length - 1 ? "" : `${url}${ele}`, 
                          params: (location.state as any),
                          prefixName: prefix && i === 0 ? routeBreadName[prefix] : undefined
                        }
                      ));
      navDom = <VhBread routes={paths} />;
      if (paths.length > 1) {
        backDom = <Button type="link" onClick={this.onGoBack.bind(this, paths[paths.length - 2].path)}><Icon type="left" />返回</Button>
      }
    }

    return (
      <div className="smartht_bread" style={style}>
        {navDom}
        {/* {backDom} */}
      </div>
    )
  }
}
);

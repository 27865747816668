export interface PageDataType {
  pageIndex: number,
  pageSize: number,
  total: number,
}

export const initPageData ={
  pageIndex: 1,
  pageSize: 25,
  total: 0,
}

export interface AgencyListType {
  [key: string]: any
}

export interface MyAgencyStateType {
  readonly pageData: PageDataType,
  readonly agencyList: AgencyListType[],
}

export enum MyAgencyType {
  /**更新我的待办state */
  FETCH_UPDATE_MYAGENCY_STATE = "@MyAgencyType/FETCH_UPDATE_MYAGENCY_STATE",
  /**获取我的待办列表数据 */
  FETCH_GET_MYAGENCYLIST = "@MyAgencyType/FETCH_GET_MYAGENCYLIST",
  /**删除待办数据 */
  FETCH_DELETE_MYAGENCYDATA = "@MyAgencyType/FETCH_DELETE_MYAGENCYDATA",
  /**将待办数据标记为已完成 */
  FETCH_COMPLETE_MYAGENCYDATA = "@MyAgencyType/FETCH_COMPLETE_MYAGENCYDATA",
}
import React from "react";
import { Switch, Route, withRouter, RouteComponentProps, Redirect } from "react-router-dom";
import { Dispatch } from "redux";
import { MsHeader, VhSider } from "msd";

import { Layout, Spin } from "antd";

import { fetchUserInfo, fetchLogout, fetchAppList, fetchConfigAppList, fetchCommonNewMap } from "store/authorization/actions";
import { connect } from "react-redux";
import { ApplicationState } from "store";

import "./MsLayout.scss";
import { CID, env, HOME_PAGE, LOGIN_URL, TOKEN } from "common/constants";
import { ICompany, IUser, MsApp } from "common/types";
import { VhMenu } from "msd/dist/vh-sider";
import companyLogo from "images/logo.png"

import VhBreadNav from "components/ms-common/VhBreadNav";
import VhMenuComp from "components/vh-menu/VhMenu";
import VhHeader from "components/vh-header/VhHeader";

import MsAuthRoute from "./MsAuthRoute";
import { registerMicroApps, start } from "qiankun";
import Tools from "utils/tools";
import { fetchMessageCount, fetchMessageList } from "store/workbrench/actions";
import { fetchUpdateCommonState } from "store/common/actions";

/**
 * state props
 *
 * @interface PropsFromState
 */
interface PropsFromState {
  userInfo: IUser;
  companyInfo: ICompany;
  configAppList: MsApp[];
  appList: MsApp[];
  appInfo: MsApp;
  menuList?: VhMenu[];
  loading: boolean;
  unReadMessageCount: number;
  collapse: boolean,
  openKeys: any[]
}

interface PropsOwn {
  [key: string]: any;
}

interface StateOwn {
  menuList: any[];
  collapse: boolean;
  /**当前应用 */
  currentApp: string;
}

/**
 * dispatch props
 *
 * @interface PropsFromDispatch
 */
interface PropsFromDispatch {
  fetchUserInfo: typeof fetchUserInfo.request;
  fetchLogout: typeof fetchLogout.request;
  fetchAppList: typeof fetchAppList.request;
  fetchCommonNewMap: typeof fetchCommonNewMap.request;
  fetchConfigAppList: typeof fetchConfigAppList.request;
  fetchMessageCount: typeof fetchMessageCount;
  fetchUpdateCommonState: typeof fetchUpdateCommonState
}

type AllProps = PropsFromState & PropsOwn & RouteComponentProps & PropsFromDispatch;

// 关联state到组件props
const mapStateToProps = ({ authorizationState, workBenchState, commonState }: ApplicationState) => ({
  userInfo: authorizationState.userInfo,
  companyInfo: authorizationState.companyInfo,
  configAppList: authorizationState.configAppList,
  appList: authorizationState.appList,
  appInfo: authorizationState.appInfo,
  menuList: authorizationState.menuList,
  loading: authorizationState.loading,
  unReadMessageCount: workBenchState.unReadMessageCount,
  openKeys: commonState.openKeys,
  collapse: commonState.collapse
});

// 关联dispatch到组件props
const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUserInfo: () => dispatch(fetchUserInfo.request()),
  fetchLogout: () => dispatch(fetchLogout.request()),
  fetchAppList: () => dispatch(fetchAppList.request()),
  fetchCommonNewMap: () => dispatch(fetchCommonNewMap.request()),
  fetchConfigAppList: () => dispatch(fetchConfigAppList.request()),
  fetchMessageCount: () => dispatch(fetchMessageCount()),
  fetchUpdateCommonState: (data: any) => dispatch(fetchUpdateCommonState(data))
});

/**
 * 布局组件
 * 1、使用Provider提供redux state
 * 2、ConnectedRouter连接redux和router
 * 3、LocaleProvider提供antd中文设置
 * 4、加载Home组件
 *
 * @class App
 * @extends {React.Component<AppProps>}
 */
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
  class MsLayout extends React.Component<AllProps, StateOwn> {

    public ehrUrl = `http://${env === "pro" ? "hr" : "testhr"}.viphrm.com/login/ui/user/login.html?ms_cid=${CID}&ms_member_token=${TOKEN}&ticket=${TOKEN}&appCode=1011&noBanner=true`

    public externalAddr = `${this.ehrUrl}&t=${new Date().getTime()}`;

    /**
     * 菜单权限获取成功后更新路由列表
     *
     * @static
     * @param {AllProps} nextProps
     * @param {StateOwn} prevState
     * @returns
     * @memberof MsStart
     */
    public static getDerivedStateFromProps(nextProps: AllProps, prevState: StateOwn) {
      if (nextProps.menuList !== prevState.menuList) {
        return {
          menuList: nextProps.menuList
        };
      }
      return null;
    }

    constructor(props: AllProps) {
      super(props);
      props.fetchAppList()
      props.fetchConfigAppList()
      props.fetchCommonNewMap()
      props.fetchUserInfo()
      props.fetchMessageCount()

      this.state = {
        menuList: [],
        collapse: false,
        currentApp: "smartHR"
      }
    }

    public componentDidMount() {
      start()
    }

    /**
     * 保存常用应用
     *
     * @memberof VhLayout
     */
    public onSave = (ids: string[]) => {
      this.props.fetchAppSave({
        appIds: ids.join(",")
      })
    }

    /**
     * 登出
     *
     * @memberof VhLayout
     */
    public onLogout = () => {
      this.props.fetchLogout();
      Tools.delCookie("ms_member_token")
      Tools.delCookie("ms_cid")
      window.location.href = LOGIN_URL;
    }

    /**
    * 菜单展开/收缩方法，传入头部组件使用
    */
    public onSetCollapse = () => {
      this.props.fetchUpdateCommonState({openKeys: []})
      setTimeout(() => {
        this.setState({ collapse: !this.state.collapse }, () => {
          setTimeout(() => {
            this.props.fetchUpdateCommonState({collapse: this.state.collapse})
          }, 300)
        })
      })
    }

    /** 切换应用 */
    public switchApp = (type: string) => {
      if (type === "ehr") {
        window.open(this.externalAddr)
      }
    }

    // 渲染
    public render() {
      const { menuList = [], collapse } = this.state;
      const { location, companyInfo, userInfo, appList, configAppList, unReadMessageCount } = this.props

      const routeBreadName: any = {
        "": "smartHR",
        "workbench": "工作台",
        "workbench/message": "消息",
        "workbench/my-agency": "我的代办",
        "workbench/service-progress": "服务进度看板",
        "workbench/new-message": "消息",
        "salaryConfigManager": "薪资管理",
        "salaryConfigManager/salary-manager": "薪资核算",
        "salaryConfigManager/salary-manager/payroll-createadd": "新建工资单",
        "salaryConfigManager/salary-manager/payroll-createedit": "更新数据",
        "salaryConfigManager/salary-manager/payroll-detail": "工资单详情",
        "salaryConfigManager/salary-config": "薪资组配置",
        "salaryConfigManager/salary-config/editSalaryGroup": "编辑薪资组",
        "salaryConfigManager/salary-payslip": "电子工资条",
        "salaryConfigManager/salary-payslip/payslip-add": "新建",
        "salaryConfigManager/salary-payslip/payslip-add-enterprise": "查看",
        "salaryConfigManager/salary-payslip/payslip-detail": "工资详情",
        "salaryConfigManager/salary-attachment": "专项附加数据管理",
        "salaryConfigManager/salary-attachment/attachment-detail": "详情",
        "salaryConfigManager/taxdeclare-feedback": "个税申报反馈",
        "salaryConfigManager/taxdeclare-feedback/taxdeclare-detail": "详情",
        "paySalaryManager": "发放管理",
        "paySalaryManager/pay-salary-records": "发放记录查询",
        "paySalaryManager/pay-salary-records/person-list": "查看名单",
      }

      const isChildView = location.pathname.startsWith("/salaryConfigManager") || location.pathname.startsWith("/paySalaryManager");
      const showBread = location.pathname !== "/workbench" && location.pathname !== "/"
      return (
        <Layout className="smartHR-content">
          <VhHeader
            appList={configAppList}
            picture={userInfo.picture}
            name={userInfo.realName}
            companyName={companyInfo.name}
            logout={this.onLogout}
            collapse={this.state.collapse}
            setCollapse={this.onSetCollapse}
            // routeBreadName={routeBreadName}
            switchApp={this.switchApp}
            menuList={menuList}
            unReadMessageCount={unReadMessageCount}
          />
          <Layout>
            <VhMenuComp 
              menu={menuList} 
              realName={userInfo.realName}
              name={companyInfo.name} 
              title="工薪记" 
              openKeysList={this.props.openKeys} 
              collapse={this.state.collapse} 
              companyLogo={companyLogo} 
              companyCid={companyInfo.companyId} 
            />
            <Layout.Content style={location.pathname === "/workbench" || location.pathname === "/" ? { padding: 0 } : { padding: "0 20px 20px" }}>
              {showBread && <VhBreadNav routeBreadName={routeBreadName} style={{ marginBottom: 20 }} />}
              <>
                <div id="childView" style={{ minHeight: "calc(100% - 62px)", display: isChildView ? "block" : "none" }} />
                {menuList && menuList.length ? isChildView ? null : (
                  <Switch>
                    <MsAuthRoute menuList={menuList} />
                    <Redirect to="/workbench" />
                  </Switch>
                ) : null}
              </>
            </Layout.Content>
          </Layout>
        </Layout>
      )
    }
  }))

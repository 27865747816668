import React, { useEffect } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Spin, Table, Tooltip } from "antd";
import { ApplicationState } from "store";
import { fetchProgressBoardPageList } from "store/workbrench/actions";
import { CID, TDAPP } from "common/constants";
import { VhTools } from "msd";
import LookButton from "../LookButton"
import "./VhServiceBoard.scss";
import moment from "moment";

const mapState = ({ commonState, workBenchState, authorizationState }: ApplicationState) => ({
  /** 加载 */
  loading: commonState.loading,
  /** 服务看板列表 */
  progressBoardList: workBenchState.progressBoardList,
  /** 服务类型 */
  boardTypeData: authorizationState.commonNewMap["coss.boardType"],
  /** 服务状态 */
  boardStatusData: authorizationState.commonNewMap["coss.boardStatus"],
  /** 菜单列表 */
  menuList: authorizationState.menuList || []
})

interface PropsOwn extends RouteComponentProps {
   /** 公司名称 */
  name: string;
  /** 姓名 */
  realName: string;
}

/** 工作台-服务进度看板 */
const VhServiceBoard = (props: PropsOwn) => {

  const { loading, progressBoardList, boardTypeData, boardStatusData, menuList } = useSelector(mapState)

  const dispatch = useDispatch()

  const { history } = props

  /** 查询服务进度看板列表 */
  useEffect(() => {
    dispatch(fetchProgressBoardPageList({
      pageIndex: 1,
      pageSize: 10,
      cid: CID
    }))
  }, [])

  /** 返回省略显示 */
  const onSetToolTip = (text: string, direction: any = "top") => (
    (text || typeof (text) === "number")
      ? (
        <div className="tip-remark">
          <Tooltip title={text} placement={direction}>
            {text.length > 20 ? `${text.substr(0, 19)}...` : text}
          </Tooltip>
        </div>
      )
      : "--"
  )

  /** 状态列 */
  const columns: any[] = [
    { title: "时间", dataIndex: "createDate", key: "createDate", width: 174, align: "left", render: (text: string) => text || "--" },
    { title: "服务类型", dataIndex: "boardType", key: "boardType", width: 100, align: "left", render: (text: string) => (text || text === "0") ? VhTools.mappingFilter(boardTypeData, text.toString()) : "--" },
    // { title: "服务描述", dataIndex: "boardDesc", key: "boardDesc", width: 300, align: "left", render: (text: string) => text ? onSetToolTip(text) : "--" },
    { title: "服务描述", dataIndex: "boardDesc", key: "boardDesc", width: 354, align: "left", render: (text: string) => text ? (<Tooltip title={text} placement="top">
      <span className="whiteSpace" style={{maxWidth: 330}}>{text}</span>
    </Tooltip>) : "--" },
    { title: "当前状态", dataIndex: "boardStatus", key: "boardStatus", width: 150, align: "left", render: (text: string) => (text || text === "0") ? VhTools.mappingFilter(boardStatusData, text.toString()) : "--" },
    // { title: "当前负责方", dataIndex: "currentChargeName", key: "currentChargeName", width: 150, align: "left", render: (text: string) => onSetToolTip(text) || "--" },
    { title: "当前负责方", dataIndex: "currentChargeName", key: "currentChargeName", width: 224, align: "left", render: (text: string) => text ? (<Tooltip title={text} placement="top">
    <span className="whiteSpace" style={{maxWidth: 200}}>{text}</span>
  </Tooltip>) : "--" },
    {
      title: "操作", dataIndex: "opt", key: "opt", width: 80, align: "center", render: (_: string, record: any) => (
        <LookButton style={{ marginRight: 10 }} record={record} menuList={menuList} />
      )
    }
  ]

  /** 查看全部 */
  const onCheckAll = () => history.push("/workbench/service-progress")

  const handleOnMouseEnter = (module: string) => {
    const { realName, name } = props;
    const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
    TDAPP.onEvent("进入工作台模块",`${name}_${realName}`,{"用户名":`${realName}_${timestamp}`,"公司" : `${name}_${timestamp}`,"模块": `${module}_${timestamp}`})
  }

  const handleOnMouseLeave = (module: string) => {
    const { realName, name } = props;
    const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
    TDAPP.onEvent("离开工作台模块",`${name}_${realName}`,{"用户名":`${realName}_${timestamp}`,"公司" : `${name}_${timestamp}`,"模块": `${module}_${timestamp}`})
  }

  return (
    <Card
      onMouseEnter={ handleOnMouseEnter.bind(VhServiceBoard,"服务进度看板") } onMouseLeave = { handleOnMouseLeave.bind(VhServiceBoard,"服务进度看板") }
      bordered={false}
      className="service-board-card"
      title={(
        <div>
          <span className="title">服务进度看板</span>
          <span className="subtitle">仅显示最近约10条记录</span>
        </div>
      )}
      extra={progressBoardList.length > 0 && (
        <Button type="link" onClick={onCheckAll}>查看全部</Button>
      )}
    >
    <Spin spinning={loading.showA} tip={loading.text} size="large">
      <Table
        rowKey="id"
        dataSource={progressBoardList}
        columns={columns}
        pagination={false}
        scroll={{x: columns.reduce((res: number, item: any) => res += (item.width || 150), 0)}}
      />
      </Spin>
    </Card>
  )
}

export default withRouter(VhServiceBoard)
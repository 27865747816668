// loading类型
export interface LoadingType {
  showA: boolean;                 // 外层加载
  showB: boolean;                 // 弹窗加载
  showC: boolean;                 // 更多加载
  text: string;                   // 加载文案
  second: number;                 // 加载倒计时
}

// 导出类型
export interface ExportType {
  showRate: boolean;
  rate: number;
}

export interface CommonState {
  readonly loading: LoadingType;
  readonly exportExcel: ExportType;
  readonly openKeys: any[];
  readonly collapse: boolean;
}

export enum CommonActionTypes {
  // 加载
  FETCH_LOADING = "页面加载/FETCH_LOADING",
  // 导出获取taskId
  FETCH_EXPORT_EXCEL = "@@export_excel/FETCH_EXPORT_EXCEL",
  // 根据taskId轮询导出
  FETCH_EXPORT_BY_TASKID = "@@export_by_taskid/FETCH_EXPORT_BY_TASKID",
  FETCH_EXPORT_BY_TASKID_FINISH = "@@export_by_taskid/FETCH_EXPORT_BY_TASKID_FINISH",
  // 更新公共state
  FETCH_UPDATE_COMMON_STATE = "更新公共state/FETCH_UPDATE_COMMON_STATE",
}
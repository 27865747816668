import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import { createBrowserHistory, createHashHistory } from "history";
import configureStore from "./configureStore";
import Tools from "utils/tools";
import * as serviceWorker from "./serviceWorker";
import { registerMicroApps, start, } from "qiankun";
import { PAY_SALARY_MANAGER_URL, SALARY_CONFIG_MANAGER_URL, TOKEN, CID, TDAPP } from "common/constants"
import "../node_modules/react-grid-layout/css/styles.css"
import "../node_modules/react-resizable/css/styles.css"

const history = Tools.supportsHistory() ? createBrowserHistory() : createHashHistory();

// 从window对象中获取初始state
const initialState = (window as any).initialReduxState;
// 配置store
const store = configureStore(history, initialState);

ReactDOM.render(<App store={store} history={history} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

function genActiveRule(routerPrefix: string) {
  return (location: any) => {
    if (location && location.pathname) {
      return location.pathname.startsWith(routerPrefix);
    } else {
      return false
    }
  }
}

registerMicroApps([
  {
    name: "viphrm-salary-manager",
    entry: `//${SALARY_CONFIG_MANAGER_URL}`,
    // entry: "//localhost:30001",
    container: "#childView",
    activeRule: genActiveRule("/salaryConfigManager"),
    props: {
      sourceAppCode: 30001,
      token: TOKEN,
      isEnterprise: true,
      preRouterUrl: "/salaryConfigManager",
      cid: CID,
      tdapp:TDAPP
    }
  },
  {
    name: "salary-cpay-web-moss",
    entry: `//${PAY_SALARY_MANAGER_URL}`,
    container: "#childView",
    activeRule: genActiveRule("/paySalaryManager"),
    props: {
      sourceAppCode: 30002,
      token: TOKEN,
      isEnterprise: true,
      preRouterUrl: "/paySalaryManager",
      cid: CID,
    }
  },
]);

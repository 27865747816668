import { Icon } from "antd";
import "antd/lib/icon/style";

/**
 * icon地址：
 * https://www.iconfont.cn/
 */
const VhIcon = Icon.createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/font_1137289_eaexo1v91d.js"
});

export default VhIcon;

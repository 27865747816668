import React, { Component } from "react";
import { Button } from "antd";
import { SOURCE_APPCODE } from "common/constants";
import Pic from "./assets/img/no_auth.png";
import "./MsNoAuth.scss";

class MsNoAuth extends Component {

  /**
   * 退出到moa页面
   */
  public onToMainPage  = () => {
    SOURCE_APPCODE
    ? (window.parent.location.href = document.referrer)
    : (window.location.href = "/");
  }

  public render() {
    return (
      <div className="no-auth">
        <img src={Pic} alt="无权限" />
        <p>抱歉，您无权访问该页面！</p>
        <Button type="default" onClick={this.onToMainPage}>返回首页</Button>
      </div>
    )
  }
}

export default MsNoAuth
export interface PageDataType {
  pageIndex: number,
  pageSize: number,
  total: number,
}

export const initPageData = {
  pageIndex: 1,
  pageSize: 25,
  total: 0,
}

export interface ProgressBoardListType {
  [key: string]: any
}


export interface MessageListType {
  [key: string]: any
}

export interface SalaryStatisticsType {
  /** 发放中批次数 */
  failNum: number;
  /** 失败需重发批次数 */
  needResendAmoutSum: number;
  /** 失败需重发笔数 */
  needResendNum: number;
  /** 失败需重发总金额 */
  processNum: number;
}

export interface ProgressBoardAllCountType {
  /** 待我方处理 */
  byWzNum: number;
  /** 待服务方处理 */
  byServiceNum: number;
  /** 待处理 */
  pendingNum: number;
  /** 进行中 */
  ongoingNum: number;
  /** 已完成 */
  completedNum: number;
}

export interface ProgressBoardLogType {
  id: number;
  boardId: number;
  boardType: number;
  createTime: string;
  creatorName: string;
  operateType: number;
}

export interface WorkBenchStateType {
  readonly progressBoardList: ProgressBoardListType[];
  readonly progressBoardListPagination: PageDataType;
  readonly progressBoardLog: ProgressBoardLogType[];
  readonly messageList: MessageListType[];
  readonly messageListPagination: PageDataType;
  readonly salaryStatistics: SalaryStatisticsType;
  readonly progressBoardAllCount: ProgressBoardAllCountType;
  readonly distinctCossServiceProgressList: any[];
  readonly agencyToDoTotal: string | number;
  readonly agencyList: any[];
  readonly unReadMessageCount: number;
  readonly unReadMessageList: MessageListType[];
}

export enum WorkBenchType {
  /** 更新工作台state */
  FETCH_UPDATE_WORKBENCH_STATE = "@WorkBenchType/FETCH_UPDATE_WORKBENCH_STATE",
  /** 服务看板分页 */
  FETCH_PROGRESS_BOARD_LIST = "@WorkBenchType/FETCH_PROGRESS_BOARD_LIST",
  /** 服务看板明细 */
  FETCH_PROGRESS_BOARD_LOG_LIST = "@WorkBenchType/FETCH_PROGRESS_BOARD_LOG_LIST",
  /** 消息分页 */
  FETCH_MESSAGE_LIST = "@WorkBenchType/FETCH_MESSAGE_LIST",
  /** 更新消息状态 */
  FETCH_UPDATE_MESSAGE_STATUS = "@WorkBenchType/FETCH_UPDATE_MESSAGE_STATUS",
  /** 获取未读消息统计 */
  FETCH_MESSAGE_COUNT = "@WorkBenchType/FETCH_MESSAGE_COUNT",
  /** 获取未读消息列表 */
  FETCH_UNREAD_MESSAGE_LIST = "@WorkBenchType/FETCH_UNREAD_MESSAGE_LIST",
  /** 获取收入发放统计 */
  FETCH_SALARY_PAY_MONTH_STATISTICS = "@WorkBenchType/FETCH_SALARY_PAY_MONTH_STATISTICS",
  /** 工作台更新 */
  FETCH_UPDATE_WORK_BENCH_INFO = "@WorkBenchType/FETCH_UPDATE_WORK_BENCH_INFO",
  /** 服务看板数据统计 */
  FETCH_PROGRESS_BOARD_ALL_COUNT = "@WorkBenchType/FETCH_PROGRESS_BOARD_ALL_COUNT",
  /** 获取责任方数据 */
  FETCH_DISTINCT_COSS_SERVICE_PROGRESS_LIST = "@WorkBenchType/FETCH_DISTINCT_COSS_SERVICE_PROGRESS_LIST",
  /** 登录人信息及页面布局 */
  FETCH_USER_INFO_AND_CONFIG_LIST = "@WorkBenchType/FETCH_USER_INFO_AND_CONFIG_LIST",
  /** 获取我的待办和统计数据 */
  FETCH_GET_AGENCY_LIST_AND_STATIC = "@WorkBenchType/FETCH_GET_AGENCY_LIST_AND_STATIC"
}
import { Reducer } from "redux"
import { AuthorizationState, AuthorizationActionTypes } from "./types";

export const initMenuList = [
  {
    functionCode: "30000",
    functionName: "工作台",
    groupIconName: "",
    groupName: "仪表盘",
    iconName: "",
    id: -999,
    visitURL: "",
    children: [
      {
        functionCode: "3000010000",
        functionName: "工作台",
        groupIconName: "",
        groupName: "仪表盘",
        iconName: "",
        id: -888,
        visitURL: "/workbench",
      }
    ]
  }
]
/**初始化state */
const initialState: AuthorizationState = {
  userInfo: {
    userId: "",
    realName: "",
    picture: "",
    gender: "",
    email: "",
    emailActive: "",
    mobile: "",
    mobileActive: ""
  },
  companyInfo: {
    companyId: "",
    companyLogo: "",
    name: "",
    userId: "",
  },
  appList: [],
  configAppList: [],
  appInfo: {
    appId: "",
    appCode: "",
    appName: ""
  },
  // menuList: undefined,
  menuList: [],
  commonNewMap: {},
  errors: undefined,
  loading: false,
};

/**申请开票列表页面reducer定义 */
const reducer: Reducer<AuthorizationState> = (state = initialState, action) => {
  switch (action.type) {
    /**获取登陆用户信息 */
    case AuthorizationActionTypes.FETCH_USER_INFO: {
      return { ...state, loading: true };
    }
    case AuthorizationActionTypes.FETCH_USER_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        userInfo: action.payload,
        companyInfo: action.payload.company ? action.payload.company : initialState.companyInfo
      };
    }
    case AuthorizationActionTypes.FETCH_USER_INFO_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }

    /**获取应用列表 */
    case AuthorizationActionTypes.FETCH_APP_LIST: {
      return { ...state, loading: true };
    }
    case AuthorizationActionTypes.FETCH_APP_LIST_SUCCESS: {
      return {
        ...state,
        // loading: false,
        appList: action.payload
      };
    }
    case AuthorizationActionTypes.FETCH_APP_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }

    /**获取用户配置权限列表（个人设置，公司设置等） */
    case AuthorizationActionTypes.FETCH_CONFIG_APP_LIST: {
      return { ...state, loading: true };
    }
    case AuthorizationActionTypes.FETCH_CONFIG_APP_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        configAppList: action.payload || []
      };
    }
    case AuthorizationActionTypes.FETCH_CONFIG_APP_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }

    /**获取菜单列表 */
    case AuthorizationActionTypes.FETCH_MENU_LIST: {
      return {
        ...state,
        appInfo: action.payload,
        loading: true
      };
    }
    case AuthorizationActionTypes.FETCH_MENU_LIST_SUCCESS: {
      return {
        ...state,
        loading: false,
        menuList: action.payload
      };
    }
    case AuthorizationActionTypes.FETCH_MENU_LIST_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }

    /**获取公共数据字典(新版) */
    case AuthorizationActionTypes.FETCH_COMMON_NEW_MAP: {
      return { ...state, loading: true };
    }
    case AuthorizationActionTypes.FETCH_COMMON_NEW_MAP_SUCCESS: {
      return {
        ...state,
        loading: false,
        commonNewMap: action.payload
      };
    }
    case AuthorizationActionTypes.FETCH_COMMON_NEW_MAP_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }

    /**登出 */
    case AuthorizationActionTypes.FETCH_LOGOUT: {
      return { ...state, loading: true };
    }
    case AuthorizationActionTypes.FETCH_LOGOUT_SUCCESS: {
      return {
        ...state,
        loading: false
      };
    }
    case AuthorizationActionTypes.FETCH_LOGOUT_ERROR: {
      return {
        ...state,
        loading: false,
        errors: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export { reducer as authorizationReducer }

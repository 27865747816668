import React, { useEffect, useState } from "react"
import { Button, Card, Spin, Table, Tag, Tooltip } from "antd";
import VhServiceBoard from "./serviceBoard/VhServiceBoard";
import VhQuickAction from "./quickAction/VhQuickAction";
import VhNewMessage from "./newMessage/VhNewMessage";
import VhCalendar from "./calendar/Calendar";
import VhGrantManage from "./grantManage/VhGrantManage";
import GridLayout, { ComponentsType, GridLayoutType } from "./grid-layout/gridLayout";
import { fetchUpdateWorkbenchInfo, fetchGetAgencyData } from "store/workbrench/actions";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { LoadingType } from "store/common/types"; 
import { ICompany, IMapType, IUser } from "common/types"
import { CID, TDAPP } from "common/constants";
import { ApplicationState } from "store";
import { VhTools } from "msd";
import Tools from "utils/tools"
import fetchApi from "utils/fetchApi";
import LookButton from "./LookButton";
import personImg from "images/personBg.png";
import "./Workbench.scss";
import moment from "moment";

interface WorkbenchProps extends RouteComponentProps {
  agencyToDoTotal: string | number;
  agencyList: any[];
  loading: LoadingType;
  todoTypeData: IMapType[];
  certStatusData: IMapType[];
  menuList: any[];
  collapse: boolean;
  userAndCompany: IUser;
  companyInfo: ICompany;
  getAgencyData: typeof fetchGetAgencyData;
  fetchUpdateWorkbenchInfo: typeof fetchUpdateWorkbenchInfo;
}

const mapStateToProps = ({ commonState, authorizationState, workBenchState }: ApplicationState) => ({
  loading: commonState.loading,
  agencyToDoTotal: workBenchState.agencyToDoTotal,
  agencyList: workBenchState.agencyList,
  todoTypeData: authorizationState.commonNewMap["coss.todoType"] || [],
  certStatusData: authorizationState.commonNewMap["coss.certStatus"] || [],
  menuList: authorizationState.menuList || [],
  collapse: commonState.collapse,
  userAndCompany: authorizationState.userInfo,
  companyInfo: authorizationState.companyInfo
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getAgencyData: (params: any) => dispatch(fetchGetAgencyData(params)),
  fetchUpdateWorkbenchInfo: (params: any) => dispatch(fetchUpdateWorkbenchInfo(params)),
});

const Workbench: React.FC<WorkbenchProps> = (props) => {

  const {
    history,
    loading,
    agencyToDoTotal,
    agencyList,
    todoTypeData,
    certStatusData,
    menuList,
    getAgencyData,
    fetchUpdateWorkbenchInfo,
    collapse,
    userAndCompany
  } = props

  const [welcomeText, setWelcomeText] = useState<string>("")
  const [isDraggable, setIsDraggable] = useState<boolean>(false)
  const [agencyData, setAgencyData] = useState<any[]>([])
  const [userInfo, setUserInfo] = useState<any>()

  // 模块布局
  const [layout, setLayout] = useState<GridLayoutType[]>([
    { i: "welcome", x: 0, y: 0, w: 9, h: 2.2},
    { i: "agency", x: 0, y: 2.2, w: 9, h: 2.7 },
    { i: "board", x: 0, y: 5.4, w: 9, h: 5.6 },
    { i: "grant", x: 0, y: 9.4, w: 4.5, h: 2 },
    { i: "quick", x: 9, y: 0, w: 3, h: 1.8 },
    { i: "calendar", x: 9, y: 3.3, w: 3, h: 4.7 },
    { i: "message", x: 9, y: 8.3, w: 3, h: 4.7 },
    { i: "edit", x: 9, y: 10.5, w: 3, h: 1 },
  ])

  /** 查询角色信息和布局 */
  const getUserInfoAndConfigList = async () => {
    const res = await fetchApi("get", "cossCenter/server/work/bench/config/userInfoAndConfigList")
    if (res?.result === 0 && res?.data) {
      const { realName, roleNames, certStatus, cossWorkBenchConfigVo } = res.data
      setUserInfo({ realName, roleNames, certStatus })
      if (cossWorkBenchConfigVo?.workBenchConfig) {
        const arr = JSON.parse(cossWorkBenchConfigVo.workBenchConfig) || []
        setLayout(arr)
      }
    }
  }

  const handleOnMouseEnter = (module: string) => {
    const { realName } = props.userAndCompany;
    const { name } = props.companyInfo;
    const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
    TDAPP.onEvent("进入工作台模块",`${name}_${realName}`,{"用户名":`${realName}_${timestamp}`,"公司" : `${name}_${timestamp}`,"模块": `${module}_${timestamp}`})
  }

  const handleOnMouseLeave = (module: string) => {
    const { realName } = props.userAndCompany;
    const { name } = props.companyInfo;
    const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
    TDAPP.onEvent("离开工作台模块",`${name}_${realName}`,{"用户名":`${realName}_${timestamp}`,"公司" : `${name}_${timestamp}`,"模块": `${module}_${timestamp}`})
  }

  useEffect(() => {
    const { realName } = props.userAndCompany;
    const { name } = props.companyInfo;
    const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
    TDAPP.onEvent("进入工作台",`${name}_${realName}`,{"用户名":`${realName}_${timestamp}`,"公司" : `${name}_${timestamp}`,"网址": `${location.href}_${timestamp}`})

    const hours = new Date().getHours()
    if (hours >= 6 && hours < 12) {
      setWelcomeText("上午好")
    } else if (hours >= 12 && hours < 19) {
      setWelcomeText("下午好")
    } else {
      setWelcomeText("晚上好")
    }
    getAgencyData({})
    getUserInfoAndConfigList()
  }, [])

  useEffect(() => {
    setLayout([...layout])
  }, [collapse])

  useEffect(() => {
    const functionCodeList: string[] = []
    menuList.map((item: any) => {
      if (item.children && item.children.length) {
        item.children.forEach((val: any) => {
          functionCodeList.push(val.functionCode)
        })
      } else {
        functionCodeList.push(item.functionCode)
      }
    })
    const res = agencyList.filter((item: any) => !!functionCodeList.find((val: any) => val === item.functionCode))
    setAgencyData(res)
  }, [menuList, agencyList])

  const onMyAgency = () => {
    history.push("/workbench/my-agency")
  }

  /** 实名状态 */
  const renderRealNameStatus = (status: string) => {
    switch (+status) {
      case 1: // 未认证
        return <Tag color="#faad14">{VhTools.mappingFilter(certStatusData, status)}</Tag>;
      case 2: // 认证中
        return <Tag color="#1890ff">{VhTools.mappingFilter(certStatusData, status)}</Tag>;
      case 3: // 已认证
        return <Tag color="#52c41a">{VhTools.mappingFilter(certStatusData, status)}</Tag>;
      case 4: // 认证失败
        return <Tag color="#ff4d4f">{VhTools.mappingFilter(certStatusData, status)}</Tag>;
    }
  }

  // 欢迎语
  const WelCome = () => (
    <div className="personContent" onMouseEnter={ handleOnMouseEnter.bind(Workbench,"欢迎语") } onMouseLeave = { handleOnMouseLeave.bind(Workbench,"欢迎语") }>
      <div className="left">
        <div style={{ fontSize: 22, fontWeight: 600 }}>
          {welcomeText}，{userInfo?.realName ?? "用户"}
        </div>
        <div className="subFontColor" style={{ margin: "16px 0" }}>
          {renderRealNameStatus(userInfo?.certStatus?.toString() ?? "1")} {userInfo?.roleNames ?? "系统角色"}
        </div>
        <div className="subFontColor">
          {agencyToDoTotal > 0 ? (
            <span>你有{agencyToDoTotal}项待办事件，要注意时间准时完成哦~</span>
          ) : (
            <span>最近无待办事项，工作效率很高哦，继续加油！！</span>
          )}
        </div>
      </div>
      <div className="right"><img src={personImg}/></div>
    </div>
  )

  // 待办事项
  const Agency = () => {
    /** 表格列 */
    const agencyColumns: any[] = [
      { title: "事项类型", align: "left", dataIndex: "todoType", width: 100, key: "todoType", render: (text: any) => Tools.mappingFilter(todoTypeData, text.toString()) || "--" },
      { title: "待办内容", align: "left", dataIndex: "todoDesc", width: 360, ellipsis: true, key: "todoDesc", render: (text: any) => text ? <Tooltip title={text} placement="top">{text}</Tooltip> : "--" },
      { title: "创建时间", align: "left", dataIndex: "createDate", width: 174, key: "createDate", render: (text: any) => text || "--" },
      {
        title: "剩余处理时间", align: "left", dataIndex: "remainingTime", width: 160, key: "remainingTime", render: (text: any) => {
          if (!text && text !== 0) {
            return "--"
          }
          // const time = Math.abs(text)
          // const days = Math.floor(time / 24)
          // const hours = time % 24
          // return <span className="error-color">{`${text < 0 ? "已超时" : "还剩"}${days}天${hours}小时`}</span>
          return <span className="error-color">{`${text < 0 ? "已超时" : "还剩"}${Math.abs(text)}小时`}</span>
        }
      },
      {
        title: "操作", align: "left", dataIndex: "opt", width: 80, key: "opt", render: (_: any, record: any) => (
          <LookButton record={record} menuList={menuList} />
        )
      }
    ]
    const scrollWidth = agencyColumns.reduce((res: number, item: any) => res += item.width || 160, 0)
    return (
      <Card
        onMouseEnter={ handleOnMouseEnter.bind(Workbench,"待办事项") } onMouseLeave = { handleOnMouseLeave.bind(Workbench,"待办事项") }
        title={(
          <div>
            <span className="title">待办事项</span>
            <span className="subtitle">仅显示还没有处理最近3条代办</span>
          </div>
        )}
        bordered={false}
        className="myAgencyContent"
        extra={agencyData.length > 0 && (
          <Button type="link" onClick={onMyAgency}>查看全部</Button>
        )}>
        <Spin spinning={loading.showB}>
          <Table
            rowKey="id"
            dataSource={agencyData}
            columns={agencyColumns}
            pagination={false}
            scroll={{x: scrollWidth}}
          />
        </Spin>
      </Card>
    )
  }


  // 排序按钮
  const ActionButton = () => {
    /** 更新排序 */
    const onEdit = () => {
      setIsDraggable(false)
      fetchUpdateWorkbenchInfo({
        cid: CID,
        workBenchConfig: JSON.stringify(layout)
      })
    }
    return isDraggable ? (
      <Button  onMouseEnter={ handleOnMouseEnter.bind(Workbench,"排序按钮") } onMouseLeave = { handleOnMouseLeave.bind(Workbench,"排序按钮") } type="dashed" block={true} style={{ margin: "10px 0 20px" }} size="large" onClick={onEdit}>
        完成编辑
      </Button>
    ) : (
      <Button type="dashed" block={true} style={{ margin: "10px 0 20px" }} size="large" onClick={() => setIsDraggable(true)}>
        编辑卡片
      </Button>
    )
  }

  // 模块组件
  const components: ComponentsType[] = [
    // 欢迎语
    { key: "welcome", component: WelCome() },
    // 待办事项
    { key: "agency", component: Agency() },
    // 服务看板
    { key: "board", component: <VhServiceBoard realName = {props.userAndCompany.realName} name = { props.companyInfo.name }/> },
    // 发放管理
    { key: "grant", component: <>{menuList.some((item: any) => item.appCode === "30002") ? <VhGrantManage realName = {props.userAndCompany.realName} name = { props.companyInfo.name }/> : null}</> },
    // 快捷操作
    { key: "quick", component: <VhQuickAction realName = {props.userAndCompany.realName} name = { props.companyInfo.name } /> },
    // // 我的日程
    { key: "calendar", component: <VhCalendar realName = {props.userAndCompany.realName} name = { props.companyInfo.name } /> },
    // // 消息
    { key: "message", component: <VhNewMessage from = "workbench"/> },
    // // 编辑卡片
    { key: "edit", component: ActionButton() },
  ]

  return (
    <div className="workbench">
      {(document.querySelector(".ant-layout-sider")?.clientWidth ?? 0) > 0 && (
        <Spin spinning={loading.showB} tip={loading.text}>
          <GridLayout
            components={components}
            layout={layout}
            setLayout={setLayout}
            isDraggable={isDraggable}
            collapse={collapse}
          />
        </Spin>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Workbench)

import React, { Component } from "react";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { MapType } from "common/types";
import { Breadcrumb } from "antd";
import "./VhBread.scss";

/**
 * 面包屑路由
 * breadcrumbName: 路由名称
 * path:           路由链接
 * params:         路由参数
 * type:           参数类型 
 */
export interface VhBreadcrumbRoute {
  breadcrumbName: string;
  path?: string;
  params?: MapType;
  type?: "search" | "hash" | "state";
  /**路由中不出现的面包屑 */
  prefixName?: string;
}

/**
 * 面包屑属性
 * routes: 路由
 */
export interface VhBreadcrumbProps {
  routes: VhBreadcrumbRoute[]
}

// 合并props
type AllProps = VhBreadcrumbProps & RouteComponentProps;

/**
 * 面包屑组件
 */
class VhBread extends Component<AllProps> {
  public render() {
    return (
      <Breadcrumb className="vh-breadcrumb" separator="·">
        {
        this.props.routes && this.props.routes.map((item: VhBreadcrumbRoute, index: number) => (
            <Breadcrumb.Item key={ index }>
              { item.prefixName ? <><span style = {{ fontSize: "12px", color:"#999"}}>{`${item.prefixName}`}</span><span> - </span></> : null }
              {item.path 
              ? <Link to={ {pathname: item.path, state: item.params} }>{ item.breadcrumbName }</Link>
              : item.breadcrumbName
              }
            </Breadcrumb.Item>
          )
          )
        }
      </Breadcrumb>
    )
  }
}

export default withRouter(VhBread);

import React, { Component } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { ApplicationState } from "store/index";
import { Menu } from "store/authorization/types";
import Tools  from "utils/tools";

interface PropsFromState extends RouteComponentProps {
  menuList: any[];
}

type AllProps = PropsFromState;

const mapStateToProps = ({authorizationState}: ApplicationState) => ({
  menuList: authorizationState.menuList || []
});

const redirectPage = <Redirect to="/no-auth" />;

class MsExternal extends Component<AllProps> {

  private externalAddr: string = "";

  /**
   * 获取地址中的数字去匹配外链地址
   */
  constructor(props: AllProps) {
    super(props);
  }

  public render() {
    const { menuList, location } = this.props;
    const path = location.pathname;
    const id = path.substring(path.lastIndexOf("/") + 1);
    let externalAddr = ""
    if (id) {
      const menuData = menuList.reduce((res: any[], item: any) => {
        return res.concat(item.children || [])
      }, [])
      const url = Tools.mappingFilter(menuData, +id, "id", "externalUrl");
      console.log("111111111111111111111111111111111", url)
      if (url) {
        externalAddr = `${url}${url.indexOf("?") < 0 ? "?" : "&"}t=${new Date().getTime()}`;
      }      
    }
    if (!externalAddr) {
      return redirectPage;
    }
    return(
      <iframe src={externalAddr} title="external" className="external" />
    )
  }
}

export default withRouter(connect(mapStateToProps)(MsExternal));

import React, { Component } from "react";
import { Switch, Route, withRouter, Redirect, RouteComponentProps } from "react-router-dom";
import Workbench from "./Workbench";
import MyAgency from "./MyAgency";
import MsServiceProgress from "./ServiceProgress";
import MsNewMessage from "./NewMessage";


/**
 * 【工作台】模块二级路由
 */
export default withRouter(
  class MsSalaryRoute extends Component<RouteComponentProps> {

    constructor(props: RouteComponentProps) {
      super(props);
    }

    public render() {
      const { path } = this.props.match;
      return (
        <Switch>
          {/* 工作台 */}
          <Route path={`${path}`} exact={true} component={Workbench} />
          {/* 待办事项 */}
          <Route path={`${path}/my-agency`} exact={true} component={MyAgency} />
          {/* 消息 */}
          <Route path={`${path}/new-message`} exact={true} component={MsNewMessage} />
          {/* 服务进度看板 */}
          <Route path={`${path}/service-progress`} exact={true} component={MsServiceProgress} />
          <Redirect to="/workbench" />
        </Switch>
      )
    }
  }
);
import React, { useState, useEffect } from "react";
import { Layout, Dropdown, Menu, Button, Avatar, Icon, Breadcrumb, Badge, Popover } from "antd";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import companyLogo from "images/logo.png"
import VhIcon from "components/vh-icon/VhIcon";
import VhNewMessage from "components/vh-workbench/newMessage/VhNewMessage";
import "./aVhHeader.scss";

interface PropsOwn {
  /** 头像 */
  picture?: string;
  /** 当前用户姓名 */
  name?: string;
  /** 公司名称 */
  companyName?: string;
  /** 头部下方的应用列表 */
  appList: any[];
  /** 展开/收缩状态 */
  collapse: boolean;
  /** 退出方法 */
  logout: () => void;
  /** 点击展开/收缩方法 */
  setCollapse: () => void;
  routeBreadName?: RouterBreadType[];
  /**切换应用界面 */
  switchApp: (type: string) => void;
  /**菜单列表 */
  menuList: any[];
  /** 未读消息数 */
  unReadMessageCount: number;
}

interface RouterBreadType {
  name: string,
  path: string,
  state: any,
}

type AllProps = PropsOwn & RouteComponentProps;

const { Header } = Layout;
const { Item } = Menu;

const VhHeader = (props: AllProps) => {
  const [routerBreadData, setRouterVreadData] = useState<RouterBreadType[]>([])
  const { name, logout, picture, collapse, setCollapse, switchApp, appList, companyName, location, routeBreadName = [], unReadMessageCount } = props;

  useEffect(() => {
    const res: RouterBreadType[] = []
    routeBreadName.forEach(item => {
      if (location.pathname === item.path) {
        item.state = location.state
      }
      if (location.pathname.indexOf(item.path) > -1) {
        res.push(item)
      }
    })
    setRouterVreadData(res)
  }, [location])
  /**
   * 打开新窗口方法
   * @param url 地址
   */
  const onOpenNewPage = (url: string) => window.open(url);

  /**
   * 设置元素节点
   * @param domNode 当前节点
   */
  const onSetNode = (domNode: any) => domNode.parentNode;

  /**
   * 头像下方的应用列表元素
   */
  const appListDom: JSX.Element[] = appList.length
    ? appList.filter((app: any) => app.groupId === "1").map((ele: any) => (
      <Item key={ele.appCode}>
        <Button type="link" onClick={() => onOpenNewPage(ele.appUrl || "")}>
          <VhIcon type={ele.appFontName && ele.appFontName.startsWith("ms-") ? ele.appFontName : "ms-more-app"} />{ele.appName}
        </Button>
      </Item>
    ))
    : [];

  /**
   * 展开的元素
   */
  const overlayDom: JSX.Element = (
    <Menu className="header-dropdown">
      {/* <Item key="2">
        <Button type="link" onClick={() => onOpenNewPage(PERSONAL_URL)}>
          <VhIcon type="ms-personal" />个人中心
        </Button>
      </Item> */}
      {appListDom}
      <Item key="1">
        <Button type="link" onClick={logout}>
          <VhIcon type="ms-quit" />退出
        </Button>
      </Item>
    </Menu>
  )

  return (
    <Header className="smartht_header">
      <div style={{ display: "flex", alignItems: "center" }}>
        <span style={{ height: 60, display: "flex", alignItems: "center", width: 200, justifyContent: "center" }}>
          <img style={{ height: 36 }} src={companyLogo} />
        </span>
        <Icon type={collapse ? "menu-unfold" : "menu-fold"} style={{ fontSize: 20, marginRight: 40 }} onClick={setCollapse} />
        {/* <Button type="link" onClick={switchApp.bind(VhHeader, "smartHR")}>工作台</Button> */}
        <Button type="link" onClick={switchApp.bind(VhHeader, "ehr")}>派单中心</Button>
        <Breadcrumb className="vh-breadcrumb" separator="/">
          {
            routerBreadData.map((item: RouterBreadType, index: number) => (
              <Breadcrumb.Item key={index}>
                {index === 0 ? <span style={{ fontSize: "14px", color: "#333" }}>{`${item.name}`}</span> : (
                  <Link className="linkRouter" style={index === routerBreadData.length - 1 ? { color: "#1890FF" } : { color: "#333" }} to={{ pathname: item.path, state: item.state }}>{item.name}</Link>
                )}
              </Breadcrumb.Item>
            )
            )
          }
        </Breadcrumb>
      </div>
      <div className="header-right">
        <Popover placement="bottom" content={<VhNewMessage unReadMode={true} />} trigger="click">
          <Badge dot={unReadMessageCount > 0}>
            <Icon type="bell" style={{ fontSize: 22 }} />
          </Badge>
        </Popover>
        <Dropdown
          placement="bottomRight"
          getPopupContainer={onSetNode}
          overlay={overlayDom}
        >
          <div>
            <Avatar size={40} src={picture}>
              <VhIcon type="ms-avater-gray" />
            </Avatar>
            <div style={{ marginLeft: 6 }}>
              <p style={{ fontWeight: "bold" }} title={name}>{name}</p>
              <p style={{ color: "#666" }} title={companyName}>{companyName}</p>
            </div>
          </div>
        </Dropdown>
      </div>
    </Header>
  )
}

export default withRouter(VhHeader);

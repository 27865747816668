import { Reducer } from "redux";
import { WorkBenchType, WorkBenchStateType, initPageData } from "./types";

// 初始化state
export const initCommonState: WorkBenchStateType = {
  progressBoardList: [],
  progressBoardListPagination: initPageData,
  messageList: [],
  messageListPagination: initPageData,
  salaryStatistics: {
    failNum: 0,
    needResendAmoutSum: 0,
    needResendNum: 0,
    processNum: 0
  },
  progressBoardLog: [],
  progressBoardAllCount: {
    byWzNum: 0,
    byServiceNum: 0,
    pendingNum: 0,
    ongoingNum: 0,
    completedNum: 0
  },
  distinctCossServiceProgressList: [],
  agencyToDoTotal: "",
  agencyList: [],
  unReadMessageCount: 0,
  unReadMessageList: []
};

const workBenchReducer: Reducer<WorkBenchStateType> = (state = initCommonState, action) => {
  switch (action.type) {
    // 更新单个state
    case WorkBenchType.FETCH_UPDATE_WORKBENCH_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return { ...state };
    }
  }
}

export { workBenchReducer }

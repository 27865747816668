import React, { ReactNode } from "react";
import { Input, InputNumber, Cascader, DatePicker, Select, Col, Form, Radio, Checkbox } from "antd";
import { FormComponentProps, FormItemProps, ValidationRule } from "antd/lib/form";
import { ColProps } from "antd/lib/col";
import moment from "moment";

const FormItem = Form.Item;
const { MonthPicker, RangePicker, WeekPicker } = DatePicker;
const { TextArea } = Input;

export enum NodeType {
  input = "input",
  textArea = "textArea",
  inputNumber = "inputNumber",
  select = "select",
  cascader = "cascader",
  datePicker = "datePicker",
  monthPicker = "monthPicker",
  rangePicker = "rangePicker",
  weekPicker = "weekPicker",
  reactNode = "reactNode",
  radio = "radio",
  checkBox = "checkBox",
}

export interface OptionType {
  dataValue: string,
  dataKey: number | string,
}

export interface ContentType {
  /**每一项item的key值，form通过此key获取和设置值 */
  key: string,
  /**类型，通过此类型渲染不同的item */
  type: NodeType,
  /**item的label值 */
  label: string | ReactNode,
  /**item绑定的元素对应的antd组件的props */
  eleProps?: any,
  /**是否隐藏item，主要用于各个item之间的联动显示隐藏效果 */
  isHide?: boolean,
  /**每一项item所占的栅格大小，默认是{span: 24}占一整行 */
  colProps?: ColProps,
  /**每一项item的自己props */
  formItemProps?: FormItemProps,
  /**当type为select、radio、checkbox时，对应的数据源，每一项需要dataKey和dataValue */
  options?: Array<OptionType>,
  /**当type为reactNode时，渲染的item内容，用于一些自定义的组件（需要支持value和onChange） */
  nodeEle?: ReactNode,
  /**每一项item的配置规则，同antd */
  rules?: Array<ValidationRule>,
}

interface VhFormItemProps extends FormComponentProps {
  /**需要渲染的每一项formitem数据 */
  content: Array<ContentType>,
  /**组件通过form.create注册过后，传入进来，需要拿到getFieldDecorator方法，做双向数据绑定 */
  form: any,
  /**回填的数据，data对象里面的key值需要与content数据里面的每一项的key值相对应 */
  data?: any,
  /**样式 */
  style?: any,
  /**类名 */
  className?: string,
}

const VhFormItem: React.FC<VhFormItemProps> = (props) => {
  const { content, form, data, style, className } = props;
  const renderItem = (item: ContentType, index: number) => {
    item.colProps = item.colProps || {span: 24};
    item.formItemProps = item.formItemProps || {};
    item.rules = item.rules || [];
    const { getFieldDecorator } = form;
    if(item.isHide) {
      return null
    }
    switch (item.type) {
      case "input":
        return (
          <Col key={`item_${index}`} {...item.colProps}>
            <FormItem label={item.label} {...item.formItemProps}>
              {getFieldDecorator(item.key, {
                initialValue: data[item.key] || "",
                rules: item.rules,
              })(
                <Input placeholder="请输入" {...item.eleProps} />
              )}
            </FormItem>
          </Col>
        )
      case "textArea":
        return (
          <Col key={`item_${index}`} {...item.colProps}>
            <FormItem label={item.label} {...item.formItemProps}>
              {getFieldDecorator(item.key, {
                initialValue: data[item.key] || "",
                rules: item.rules,
              })(
                <TextArea placeholder="请输入" {...item.eleProps} />
              )}
            </FormItem>
          </Col>
        )
      case "inputNumber":
        return (
          <Col key={`item_${index}`} {...item.colProps}>
            <FormItem label={item.label} {...item.formItemProps}>
              {getFieldDecorator(item.key, {
                initialValue: data[item.key] || "",
                rules: item.rules,
              })(
                <InputNumber placeholder="请输入" style={{width: "100%"}} {...item.eleProps} />
              )}
            </FormItem>
          </Col>
        )
      case "cascader":
        return (
          <Col key={`item_${index}`} {...item.colProps}>
            <FormItem label={item.label} {...item.formItemProps}>
              {getFieldDecorator(item.key, {
                initialValue: data[item.key] || [],
                rules: item.rules,
              })(
                <Cascader placeholder="请选择" {...item.eleProps} />
              )}
            </FormItem>
          </Col>
        )
      case "select":
        return (
          <Col key={`item_${index}`} {...item.colProps}>
            <FormItem label={item.label} {...item.formItemProps}>
              {getFieldDecorator(item.key, {
                initialValue: data[item.key] || undefined,
                rules: item.rules,
              })(
                <Select placeholder="请选择" {...item.eleProps} >
                  {item.options && item.options.map(v => <Select.Option key={v.dataKey} value={v.dataKey} >{v.dataValue}</Select.Option>)}
                </Select>
              )}
            </FormItem>
          </Col>
        )
      case "radio":
        return (
          <Col key={`item_${index}`} {...item.colProps}>
            <FormItem label={item.label} {...item.formItemProps}>
              {getFieldDecorator(item.key, {
                initialValue: data[item.key] || "",
                rules: item.rules,
              })(
                <Radio.Group {...item.eleProps} >
                  {item.options && item.options.map(v => <Radio key={v.dataKey} value={v.dataKey} >{v.dataValue}</Radio>)}
                </Radio.Group>
              )}
            </FormItem>
          </Col>
        )
      case "checkBox":
        return (
          <Col key={`item_${index}`} {...item.colProps}>
            <FormItem label={item.label} {...item.formItemProps}>
              {getFieldDecorator(item.key, {
                initialValue: data[item.key] || [],
                rules: item.rules,
              })(
                <Checkbox.Group {...item.eleProps} >
                  {item.options && item.options.map(v => <Checkbox key={v.dataKey} value={v.dataKey} >{v.dataValue}</Checkbox>)}
                </Checkbox.Group>
              )}
            </FormItem>
          </Col>
        )
      case "datePicker":
        return (
          <Col key={`item_${index}`} {...item.colProps}>
            <FormItem label={item.label} {...item.formItemProps}>
              {getFieldDecorator(item.key, {
                initialValue: data[item.key] ? moment(data[item.key]) : undefined,
                rules: item.rules,
              })(
                <DatePicker {...item.eleProps} />
              )}
            </FormItem>
          </Col>
        )
      case "monthPicker":
        return (
          <Col key={`item_${index}`} {...item.colProps}>
            <FormItem label={item.label} {...item.formItemProps}>
              {getFieldDecorator(item.key, {
                initialValue: data[item.key] ? moment(data[item.key]) : undefined,
                rules: item.rules,
              })(
                <MonthPicker {...item.eleProps} />
              )}
            </FormItem>
          </Col>
        )
      case "rangePicker":
        return (
          <Col key={`item_${index}`} {...item.colProps}>
            <FormItem label={item.label} {...item.formItemProps}>
              {getFieldDecorator(item.key, {
                initialValue: data[item.key] ? moment(data[item.key]) : undefined,
                rules: item.rules,
              })(
                <RangePicker {...item.eleProps} />
              )}
            </FormItem>
          </Col>
        )
      case "weekPicker":
        return (
          <Col key={`item_${index}`} {...item.colProps}>
            <FormItem label={item.label} {...item.formItemProps}>
              {getFieldDecorator(item.key, {
                initialValue: data[item.key] ? moment(data[item.key]) : undefined,
                rules: item.rules,
              })(
                <WeekPicker {...item.eleProps} />
              )}
            </FormItem>
          </Col>
        )
      case "reactNode":
        return (
          <Col key={`item_${index}`} {...item.colProps}>
            <FormItem label={item.label} {...item.formItemProps}>
              {getFieldDecorator(item.key, {
                initialValue: data[item.key] || undefined,
                rules: item.rules,
              })(
                item.nodeEle
              )}
            </FormItem>
          </Col>
        )
    }
  }

  return (
    <React.Fragment>
      {content.map(renderItem)}
    </React.Fragment>
  )
}

VhFormItem.defaultProps = {
  content: [],
  style: {},
  className: "",
  data: {},
}

export default VhFormItem;
import { action } from "typesafe-actions";
import { CommonActionTypes } from "./types";

// 加载
export const fetchLoading = (data: any) => action(
  CommonActionTypes.FETCH_LOADING,
  data
);

// 导出获取taskId
export const fetchExportExcel = (data: any) => action(
  CommonActionTypes.FETCH_EXPORT_EXCEL,
  data
)

// 根据taskId轮询导出excel
export const fetchExportByTaskId = (taskId: any) => action(
  CommonActionTypes.FETCH_EXPORT_BY_TASKID,
  taskId
)

export const fetchExportByTaskIdFinish = (data: any) => action(
  CommonActionTypes.FETCH_EXPORT_BY_TASKID_FINISH,
  data
);

// 更新单个state
export const fetchUpdateCommonState = (data: any) => action(
  CommonActionTypes.FETCH_UPDATE_COMMON_STATE,
  data
)

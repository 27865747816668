import * as React from "react";
import ReactDOM from "react-dom";
import { Table, Pagination } from "antd";
// import { IPagination } from "common/types";
import { SOURCE_APPCODE } from "common/constants";
import { TableProps } from "antd/lib/table"

import "./VhTable.scss";

interface MsTableProps<T> extends TableProps<T> {
  rowKey?: string | ((record: T, index: number) => string);
  align?: "left" | "right" | "center";
  size?: "small" | "default" | "middle";
  columns: any;
  dataSource: any;
  loading?: any;
  rowSelection?: any;
  pagination?: any;
  className?: string;
  otherHeight?: number;
  showHeader?: boolean;
  scroll?: {
    x?: boolean | number | string;
    y?: boolean | number | string;
  };
  bordered?:boolean;
  rowClassName?: (record: T, index: number) => string;
  onChange?: (pagination: any) => void;
  onRow?: (record: T, index: number) => any;
}

interface MsTableState {
  height: number
}

/**
 * 通用table，动态设置高度
 *
 * @class MsTable
 * @extends {React.Component<MsTableProps, MsTableState>}
 */
class MsTable extends React.Component<MsTableProps<any>, MsTableState> {
  // 构造函数
  constructor(props: MsTableProps<any>) {
    super(props)

    // 初始化state
    this.state = {
      height: 300
    };
  }

  // 组件加载后
  public componentDidMount() {
    // 给window添加浏览器窗口调整大小事件
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  }

  /**
   * 组件更新后。重新设置table高度
   *
   * @param {MsTableProps} props
   * @param {MsTableState} state
   * @memberof MsTable
   */
  public componentDidUpdate(prevProps: MsTableProps<any>) {
    if (this.props.dataSource !== prevProps.dataSource) {
      this.setTableHeight(this.state.height);
    }
    if(this.props.otherHeight !== prevProps.otherHeight) {
      this.handleResize()
    }
  }

  // 组件卸载前
  public componentWillUnmount() {
    // 删除window窗口调整事件
    window.removeEventListener("resize", this.handleResize);
  }

  /**
   * 切换页码或是切换pageSize
   *
   * @memberof MsTable
   */
  public onChange = (type: number, page: number, pageSize: number) => {
    if (this.props.onChange) {
      // 切换pageSize的时候，重置页码为1
      const pagination = {current: type === 2 ? 1 : page, pageSize};
      this.props.onChange(pagination);

      if (type === 1) {
        // eslint-disable-next-line react/no-find-dom-node
        const table: any = ReactDOM.findDOMNode(this);
        const tableBody = table.querySelector(".ant-table-body");
        tableBody.scrollTop = 0;
      }
    }
  }

  // 设置窗口调整回调函数
  public handleResize = () => {
    // table对象
    // eslint-disable-next-line react/no-find-dom-node
    const table: any = ReactDOM.findDOMNode(this);
    // layout高度 - 头部64 - margin32 - padding32
    const totalHeight: number = document.querySelector("#root")!.clientHeight - 128;
    // 减去表头和分页
    const antTableThead: any = table.querySelector(".ant-table-thead");
    // 注意：当设置showHeader时，在DidMount中antTableThead=null，原因未知
    const h = totalHeight - (antTableThead ? antTableThead.offsetHeight : 50) - (42 + (this.props.otherHeight || 0));
    // 减去搜索框等
    // h += SOURCE_APPCODE ? 128 : 0;
    // for (const item of table.parentNode.childNodes) {
    //   if (item !== table && (item.style && !item.style.float)) {
    //     const style = item.currentStyle || window.getComputedStyle(item);
    //     const top = style.marginTop;
    //     const bottom = style.marginBottom;
    //     h -= item.offsetHeight;

    //     // 减去marginTop
    //     if (top) {
    //       h -= Number(top.replace("px", ""));
    //     }

    //     // 减去marginBottom
    //     if (bottom) {
    //       h -= Number(bottom.replace("px", ""));
    //     }
    //   }
    // }

    // 设置table高度
    this.setState({height: h});
    this.setTableHeight(h);
  };

  public render() {
    let className = "ms-table-default";

    if (this.props.className) {
      className = this.props.className + " ms-table-default";
    }
    if (this.props.rowSelection) {
      className = this.props.className + " ms-table-default ms-table-default-row-select";
    }
    return (
      <div className="ms-table-container-default">
        <Table
          { ...this.props }
          className={ className }
          scroll={ {y: this.state.height, ...this.props.scroll} }
          pagination={ false }
          showHeader={this.props.dataSource.length > 0}
        />
        <div style={ {width: "100%", textAlign: "right"} }>
        {
          this.props.pagination && this.props.dataSource && this.props.dataSource.length ?
          <Pagination
            onChange={ this.onChange.bind(this, 1) }
            onShowSizeChange={ this.onChange.bind(this, 2) }
            {
              ...{
                // size: "small",
                pageSizeOptions: ["25", "50", "100"],
                showTotal: (total: number) => `共${ total }条`,
                showSizeChanger: true,
                showQuickJumper: true,
                style: {padding: "6px 0 0 0"},
                ...this.props.pagination
              }
            }
          />: null
        }
        </div>
      </div>
    )
  }

  /**
   * 设置table高度
   *
   * @private
   * @memberof MsTable
   */
  private setTableHeight = (height: number) => {
    // eslint-disable-next-line react/no-find-dom-node
    const table: any = ReactDOM.findDOMNode(this);
    const body = table.querySelector(".ant-table-body");
    const placeholder = table.querySelector(".ant-table-placeholder");
    const fixedLeft = table.querySelector(".ant-table-fixed-left");
    const fixedRight = table.querySelector(".ant-table-fixed-right");
    // 清空body高度
    if (body && body.style) {
      body.style.height = "";
    }

    // 清空placeholder高度
    if (placeholder && placeholder.style) {
      placeholder.style.height = "";
    }

    // 数据存在的场合
    if (this.props.dataSource && this.props.dataSource.length) {
      // body存在的场合
      if (body) {
        // 设置body的高度， 没有左冻结和右冻结，高度减2
        body.style.height = (height - (fixedLeft || fixedRight ? 0 : 2)) + "px";
      }
    } else {
      // 数据不存在的场合
      if (placeholder) {
        // 设置placeholder高度并居中
        placeholder.style.height = (height - 2) + "px";
        placeholder.style.paddingTop = ((height - 2) - 70) / 3 + "px";
      }
    }
  }
}

export default MsTable;

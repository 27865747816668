import { put, takeEvery, call } from "redux-saga/effects";
import { ERROR_MESSAGE, LOADING_MESSAGE } from "common/constants";
import { WorkBenchType } from "./types";
import {
  fetchUpdateWorkbenchState,
  fetchMessageList,
  fetchUpdateMessageStatus,
  fetchProgressBoardPageList,
  fetchSalaryPayMonthStatistics,
  fetchProgressBoardLog,
  fetchUpdateWorkbenchInfo,
  fetchDistinctCossServiceProgressList,
  fetchGetAgencyData,
  fetchUnReadMessageList
} from "./actions";
import { fetchLoading } from "store/common/actions"
import { VhModal } from "msd";
import fetchApi from "utils/fetchApi";
import { message } from "antd";

// 获取服务看板分页列表
function* handleFetchProgressBoardList(action: ReturnType<typeof fetchProgressBoardPageList>): any {
  try {
    yield put(fetchLoading({ showA: true, text: LOADING_MESSAGE }));
    const res = yield call(fetchApi, "post", "cossCenter/server/progress/board/manager/queryProgressBoardPageList", action.payload)
    if (res?.result === 0) {
      const pagination = {
        pageIndex: res.data.pageIndex,
        pageSize: res.data.pageSize,
        total: res.data.totalCount
      }
      yield put(fetchUpdateWorkbenchState({
        progressBoardList: res.data.records,
        progressBoardListPagination: pagination
      }))
    }
  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
  yield put(fetchLoading({ showA: false, text: "" }));
}

/** 获取消息分页列表 */
function* handleFetchMessageList(action: ReturnType<typeof fetchMessageList>): any {
  try {
    yield put(fetchLoading({ showA: true, text: LOADING_MESSAGE }));
    const res = yield call(fetchApi, "post", "cossCenter/server/message/manager/queryMessagePageList", action.payload)
    if (res?.result === 0) {
      const pagination = {
        pageIndex: res.data.pageIndex,
        pageSize: res.data.pageSize,
        total: res.data.totalCount
      }
      yield put(fetchUpdateWorkbenchState({
        messageList: res.data.records,
        messageListPagination: pagination
      }))
    }
  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
  yield put(fetchLoading({ showA: false, text: "" }));
}

/** 更新消息 */
function* handleFetchUpdateMessageStatus(action: ReturnType<typeof fetchUpdateMessageStatus>): any {
  try {
    yield put(fetchLoading({ showA: true, text: LOADING_MESSAGE }));
    const { params, callback } = action.payload
    const res = yield call(fetchApi, "post", "cossCenter/server/message/manager/updateMessage", params)
    if (res?.result === 0) {
      callback && callback()
    }
  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
  yield put(fetchLoading({ showA: false, text: "" }));
}

/** 获取未读消息数量 */
function* handleFetchMessageCount(): any {
  try {
    const res = yield call(fetchApi, "get", "cossCenter/server/message/manager/queryMessageCount")
    if (res?.result === 0) {
      yield put(fetchUpdateWorkbenchState({
        unReadMessageCount: res?.data || 0
      }))
    }
  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
}

/** 获取未读消息列表 */
function* handleFetchUnReadMessageList(action: ReturnType<typeof fetchUnReadMessageList>): any {
  try {
    yield put(fetchLoading({ showA: true, text: LOADING_MESSAGE }));
    const res = yield call(fetchApi, "post", "cossCenter/server/message/manager/queryMessagePageList", action.payload)
    if (res?.result === 0) {
      yield put(fetchUpdateWorkbenchState({
        unReadMessageList: res?.data?.records || []
      }))
    }
  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
  yield put(fetchLoading({ showA: false, text: "" }));
}

// 获取收入发放统计
function* handleFetchSalaryPayMonthStatistics(action: ReturnType<typeof fetchSalaryPayMonthStatistics>): any {
  try {
    yield put(fetchLoading({ showA: true, text: LOADING_MESSAGE }));
    const res = yield call(fetchApi, "post", "cossCenter/server/salary/pay/manager/getSalaryPayMonthStats", action.payload)
    if (res?.result === 0) {
      yield put(fetchUpdateWorkbenchState({
        salaryStatistics: {
          failNum: res.data?.failNum ?? 0,
          needResendAmoutSum: res.data?.needResendAmoutSum ?? 0,
          needResendNum: res.data?.needResendNum ?? 0,
          processNum: res.data?.processNum ?? 0
        }
      }))
    }
  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
  yield put(fetchLoading({ showA: false, text: "" }));
}

// 获取服务看板日志列表
function* handleFetchProgressBoardLogList(action: ReturnType<typeof fetchProgressBoardLog>): any {
  try {
    yield put(fetchLoading({ showA: true, text: LOADING_MESSAGE }));
    const res = yield call(fetchApi, "get", `cossCenter/server/progress/board/manager/queryProgressBoardLog?boardId=${action.payload}`)
    if (res?.result === 0) {
      yield put(fetchUpdateWorkbenchState({
        progressBoardLog: res.data || []
      }))
    }
  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
  yield put(fetchLoading({ showA: false, text: "" }));
}

// 工作台编辑
function* handleFetchUpdateWorkBenchInfo(action: ReturnType<typeof fetchUpdateWorkbenchInfo>): any {
  try {
    yield put(fetchLoading({ showB: true, text: LOADING_MESSAGE }));
    const res = yield call(fetchApi, "post", "cossCenter/server/work/bench/config/updateInfo", action.payload)
    if (res?.result === 0) {
      message.success("编辑成功！")
    }
  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
  yield put(fetchLoading({ showB: false, text: "" }));
}

// 服务看板数据统计
function* handleFetchProgressBoardAllCount(): any {
  try {
    yield put(fetchLoading({ showA: true, text: LOADING_MESSAGE }));
    const res = yield call(fetchApi, "get", "cossCenter/server/progress/board/manager/queryAllCount")
    if (res?.result === 0) {
      yield put(fetchUpdateWorkbenchState({
        progressBoardAllCount: {
          byWzNum: res.data?.byWzNum ?? 0,
          byServiceNum: res.data?.byServiceNum ?? 0,
          pendingNum: res.data?.pendingNum ?? 0,
          ongoingNum: res.data?.ongoingNum ?? 0,
          completedNum: res.data?.completedNum ?? 0
        }
      }))
    }
  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
  yield put(fetchLoading({ showA: false, text: "" }));
}

// 获取责任方数据
function* handleFetchDistinctCossServiceProgressList(action: ReturnType<typeof fetchDistinctCossServiceProgressList>): any {
  try {
    yield put(fetchLoading({ showA: true, text: LOADING_MESSAGE }));
    const res = yield call(fetchApi, "post", "cossCenter/server/progress/board/manager/queryDistinctCossServiceProgreList", action.payload)
    if (res?.result === 0) {
      yield put(fetchUpdateWorkbenchState({
        distinctCossServiceProgressList: res?.data || []
      }))
    }
  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
  yield put(fetchLoading({ showA: false, text: "" }));
}

// 登录人信息及页面布局
function* handleFetchUserInfoAndConfigList(): any {
  try {
    yield put(fetchLoading({ showA: true, text: LOADING_MESSAGE }));
    const res = yield call(fetchApi, "get", "cossCenter/server/work/bench/config/userInfoAndConfigList")
    if (res?.result === 0) {
      console.log("登录人信息及页面布局", res)
    }
  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
  yield put(fetchLoading({ showA: false, text: "" }));
}

// 获取我的待办和统计数据
function* handleGetAgencyData(action: ReturnType<typeof fetchGetAgencyData>): any {
  try {
    yield put(fetchLoading({ showB: true, text: LOADING_MESSAGE }));
    const res = yield call(fetchApi, "get", "cossCenter/server/todoList/manager/userInfoAndTodoList", action.payload)
    if (res?.result === 0) {
      yield put(fetchUpdateWorkbenchState({
        agencyList: res.data?.cossTodoVoList || [],
        agencyToDoTotal: res.data?.todoTotal ?? ""
      }))
    }
  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
  yield put(fetchLoading({ showB: false, text: "" }));
}

// 监控，根据指定action类型，调用saga方法
function* workBenchSaga() {
  yield takeEvery(WorkBenchType.FETCH_PROGRESS_BOARD_LIST, handleFetchProgressBoardList);
  yield takeEvery(WorkBenchType.FETCH_MESSAGE_LIST, handleFetchMessageList);
  yield takeEvery(WorkBenchType.FETCH_SALARY_PAY_MONTH_STATISTICS, handleFetchSalaryPayMonthStatistics);
  yield takeEvery(WorkBenchType.FETCH_PROGRESS_BOARD_LOG_LIST, handleFetchProgressBoardLogList);
  yield takeEvery(WorkBenchType.FETCH_UPDATE_WORK_BENCH_INFO, handleFetchUpdateWorkBenchInfo);
  yield takeEvery(WorkBenchType.FETCH_PROGRESS_BOARD_ALL_COUNT, handleFetchProgressBoardAllCount);
  yield takeEvery(WorkBenchType.FETCH_DISTINCT_COSS_SERVICE_PROGRESS_LIST, handleFetchDistinctCossServiceProgressList);
  yield takeEvery(WorkBenchType.FETCH_USER_INFO_AND_CONFIG_LIST, handleFetchUserInfoAndConfigList);
  yield takeEvery(WorkBenchType.FETCH_GET_AGENCY_LIST_AND_STATIC, handleGetAgencyData);
  yield takeEvery(WorkBenchType.FETCH_UPDATE_MESSAGE_STATUS, handleFetchUpdateMessageStatus);
  yield takeEvery(WorkBenchType.FETCH_MESSAGE_COUNT, handleFetchMessageCount);
  yield takeEvery(WorkBenchType.FETCH_UNREAD_MESSAGE_LIST, handleFetchUnReadMessageList);
}

export default workBenchSaga;

import React, { Component } from "react";
import { Route, RouteComponentProps, withRouter, Redirect } from "react-router-dom";
import { Menu } from "store/authorization/types";
import NoAuth from "../ms-noauth/MsNoAuth"
import MsHome from "components/ms-home/MsHome";
import MsExternal from "components/ms-external/MsExternal"
import WorkbenchRoute from "components/vh-workbench/WorkbenchRoute"


/**
 * 匹配的路由页面类型
 * code:      权限的编码
 * exact:     路由是否完全匹配
 * component: 匹配的组件
 */
export interface AuthRoute {
  code: string;
  exact: boolean;
  component: any;
}

/**
 * 菜单权限拓展
 * params:   路由后面的参数
 */
export interface AuthMenu extends Menu {
  params?: string;
  children?: any[]
}

/**
 * 父组件传递数据
 *
 * @interface PropsOwn
 */
interface PropsOwn {
  menuList: AuthMenu[];
}

type AllProps = PropsOwn & RouteComponentProps;

const reRoute = <Redirect to="/no-auth" />

class MsAuthRoute extends Component<AllProps> {
  public render() {
    // 获取链接地址以及menu列表
    const { location, menuList = [] } = this.props;
    if (!menuList[0]?.children?.length) {
      return null
    }
    const routes: AuthRoute[] = [
      { code: "3000010000", exact: false, component: WorkbenchRoute },
    ];

    const path = location.pathname;
    switch (path) {
      case "/": {
        return <Route path="/" exact={false} component={WorkbenchRoute} />
      }
      case "/no-auth": {
        return <Route path="/no-auth" exact={true} component={NoAuth} />;
      }
    }
    const menuData = menuList.reduce((res: AuthMenu[], item: AuthMenu) => {
      return res.concat(item.children || [])
    }, [])

    const menu: AuthMenu | undefined = menuData.find((item: AuthMenu) =>
      item.visitURL
        // ? location.pathname === item.visitURL
        ? location.pathname.indexOf(item.visitURL) > -1
        : false
    );
    // 当前链接地址有权限的场合
    if (menu) {
      if (menu.externalUrl) {
        return <Route path={menu.visitURL} exact={true} component={MsExternal} />
      }
      const route = menu.functionCode && routes.find((item: AuthRoute) => item.code === menu.functionCode);
      return route
        ? <Route path={`${menu.visitURL}${menu.params ? menu.params : ""}`} exact={route.exact} component={route.component} />
        : reRoute
    } else {
      return reRoute
    }
  }
}

export default withRouter(MsAuthRoute);

import React from "react";
import { Card, Row, Col, Icon } from "antd";
import "./VhQuickAction.scss";

import {ReactComponent as MoneyCompent} from "../../../images/money.svg";
import { TDAPP } from "common/constants";
import moment from "moment";

interface PropsOwn {
  realName: string;
  name: string;
}

/** 工作台-快捷操作 */
const VhQuickAction = (props: PropsOwn) => {

  const quickData = [
    {
    name: "一键核算",
    path: "/salaryConfigManager/salary-manager/payroll-createadd",
    id: 1
    }
  ]

  const onGoPage = (data: any) => {
    const { realName, name } = props;
    const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
    TDAPP.onEvent("进行快捷操作",`${name}_${realName}`,{"用户名":`${realName}_${timestamp}`,"公司" : `${name}_${timestamp}`,"捷径": `${data.name}_${timestamp}`})
    window.history.pushState(data, data.name, data.path)
  }
  return (
    <Card className="quick-action-card" title="快捷操作" bordered={false}>
      <Row>
        {quickData.map((item: any) => (
          <Col key={item.id} span={8} style={{padding: "0 10px", minWidth: 120}}>
            <div className="box" onClick={() => onGoPage(item)}>
              <Icon style={{fontSize: 18, marginRight: 10}} component={MoneyCompent} />
              <span>{item.name}</span>
            </div>
          </Col>
        ))}
      </Row>
    </Card>
  )
}

export default VhQuickAction
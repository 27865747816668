import { put, takeEvery, call, delay, takeLatest, select } from "redux-saga/effects";
import { ERROR_MESSAGE, LOADING_MESSAGE, defaultUrl } from "common/constants";
import { MyAgencyType } from "./types";
import { fetchUpdateMyAgencyState, fetchGetMyAgencyList, fetchDeleteMyAgencyData, fetchCompleteMyAgencyData } from "./actions";
import {fetchLoading} from "store/common/actions"
import  { VhModal } from "msd";
import fetchApi from "utils/fetchApi";
import { message } from "antd";

// 获取我的待办列表数据
function* getMyAgencyList(action: ReturnType<typeof fetchGetMyAgencyList>) {
  try {
    yield put(fetchLoading({showB: true, text: LOADING_MESSAGE}));
    const params = action.payload;
    const res = yield call(fetchApi, "post", "cossCenter/server/todoList/manager/queryTodoListPageList", params)
    if(res?.result === 0) {
      const pageData = {
        pageIndex: res.data.pageIndex,
        pageSize: res.data.pageSize,
        total: res.data.totalCount
      }
      yield put(fetchUpdateMyAgencyState({agencyList: res.data.records || [], pageData}))
    }

  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
  yield put(fetchLoading({ showB: false, text: "" }));
}

// 删除待办数据
function* deleteMyAgencyData(action: ReturnType<typeof fetchDeleteMyAgencyData>) {
  try {
    yield put(fetchLoading({ showB: true, text: LOADING_MESSAGE }));
    const { params, callback } = action.payload;
    const res = yield call(fetchApi, "post", "cossCenter/server/todoList/manager/updateTodoList", params)
    if(res?.result === 0) {
      message.success("删除成功")
      callback && callback()
    }
  } catch (err) {
    VhModal.error({content: err instanceof Error ? err.stack! : ERROR_MESSAGE});
  }
  yield put(fetchLoading({showB: false, text: ""}));
}

// 将待办数据标记为已完成
function* completeMyAgencyData(action: ReturnType<typeof fetchCompleteMyAgencyData>) {
  try {
    yield put(fetchLoading({showB: true, text: LOADING_MESSAGE}));
    const { params, callback } = action.payload;
    const res = yield call(fetchApi, "post", "cossCenter/server/todoList/manager/updateTodoList", params)
    if(res?.result === 0) {
      callback && callback()
    }
  } catch (err) {
    VhModal.error({ content: err instanceof Error ? err.stack! : ERROR_MESSAGE });
  }
  yield put(fetchLoading({ showB: false, text: "" }));
}

// 监控，根据指定action类型，调用saga方法
function* myAgencySaga() {
  yield takeEvery(MyAgencyType.FETCH_GET_MYAGENCYLIST, getMyAgencyList);
  yield takeEvery(MyAgencyType.FETCH_DELETE_MYAGENCYDATA, deleteMyAgencyData);
  yield takeEvery(MyAgencyType.FETCH_COMPLETE_MYAGENCYDATA, completeMyAgencyData);
}

export default myAgencySaga;

import { call, put, takeEvery, select } from "redux-saga/effects";
import fetchApi from "../../utils/fetchApi";
import { ERROR_MESSAGE, showSmartHRApplist, TOKEN } from "common/constants";
import { AuthorizationActionTypes } from "./types";
import {
  fetchUserInfo,
  fetchLogout,
  fetchAppList,
  fetchConfigAppList,
  fetchMenuList,
  fetchCommonNewMap,
} from "./actions";
import { getPreRouterUrl } from "common/constants"
import { initMenuList } from "./reducer"
/**
 * 执行获取用户信息
 *
 */
function* handleFetchUserInfo(): any {
  try {
    // // 获取数据字典
    // yield put(fetchCommonMap.request());

    // 调用异步函数，向后台发送请求
    const res = yield call(fetchApi, "get", "home-server/auth/userAndCompany");

    // 请求结果调度
    if (res.result === 0 && res.data) {
      yield put(fetchUserInfo.success(res.data.extra));
    } else {
      yield put(fetchUserInfo.failure(res.detail));
    }
  } catch (err) {
    const msg = err instanceof Error ? err.stack! : ERROR_MESSAGE;
    yield put(fetchUserInfo.failure(msg));
  }
}

/**
 * 执行获取应用列表
 *
 */
function* handleFetchAppList(): any {
  try {
    // 调用异步函数，向后台发送请求
    // const res = yield call(fetchApi, "get", "home-server/app/queryAppList4Company");
    const res = yield call(fetchApi, "get", "permissionServer/user/center/app/list");

    // 请求结果调度
    if (res.result === 0 && res.data) {
      const appList = res.data.records || []
      yield put(fetchAppList.success(appList));
      // 应用请求成功后刷新菜单
      const { authorizationState: { menuList = [] } } = yield select()
      const menuData: any[] = [...initMenuList]
      for (let i = 0; i < appList.length; i++) {
        if (showSmartHRApplist.includes(appList[i].appCode)) {
          menuData.push({
            appCode: appList[i].appCode,
            functionCode: appList[i].appCode,
            functionName: appList[i].appName,
            appId: appList[i].appId,
            groupName: appList[i].appName,
            visitURL: showSmartHRApplist.includes(appList[i].appCode) ? "" : appList[i].originAppUrl,
            id: appList[i].appCode,
            children: []
          })
          // 请求当前应用的菜单配置数据
          // yield put(fetchMenuList.request(appList[i]) );
          const res1 = yield call(fetchApi, "get", `permissionServer/user/leftnavgation/${appList[i].appId}`);
          // 请求结果调度
          if (res1.result === 0 && res1.data) {

            let list = res1.data.records ? res1.data.records : [];
            // const { authorizationState: { menuList = [] } } = yield select()
            if (list.length) {
              list = list.map((ele: any) => {
                const obj = menuData.find((item: any) => item.appCode === ele.sourceAppCode)
                const bool = obj?.visitURL?.startsWith("http") || false;
                if(obj.visitURL?.endsWith("/")) {
                  obj.visitURL =  obj.visitURL.substr(0, obj.visitURL.length - 1)
                }
                // const bool = !showSmartHRApplist.includes(ele.sourceAppCode)
                const preRouterUrl: string = getPreRouterUrl(ele.sourceAppCode)
                return {
                  ...ele,
                  externalUrl: bool ? obj.visitURL + ele.visitURL + `?member_token=${TOKEN}&sourceAppCode=${obj.appCode}&appCode=${obj.appCode}` : "",
                  visitURL: bool ? `/external/${ele.id}` : `${preRouterUrl}${ele.visitURL}`
                }
              });
            }
            // 将当前应用的菜单加到应用的子菜单里面
            for (let i = 0; i < menuData.length; i++) {
              if (list.length && menuData[i].appCode === list[0].sourceAppCode) {
                menuData[i].children = list
              }
            }
            // 当前应用没有给一个菜单访问权限，菜单数据里面去掉当前应用
            if(list.length === 0) {
              const index = menuData.findIndex((item: any) => item.appId === appList[i].appId)
              menuData.splice(index, 1)
            }
          }
        }
      }
      // 将当前应用生成一级菜单
      yield put(fetchMenuList.success(menuData))
    } else {
      yield put(fetchAppList.failure(res.detail));
    }
  } catch (err) {
    const msg = err instanceof Error ? err.stack! : ERROR_MESSAGE;
    yield put(fetchAppList.failure(msg));
  }
}

/**
 * 获取应用列表
 *
 */
function* handleFetchConfigAppList(): any {
  try {
    // 调用异步函数，向后台发送请求
    const res = yield call(fetchApi, "get", "home-server/user/app/list");

    // 请求结果调度
    if (res.result === 0 && res.data) {
      yield put(fetchConfigAppList.success(res.data.records));
    } else {
      yield put(fetchConfigAppList.failure(res.detail));
    }
  } catch (err) {
    const msg = err instanceof Error ? err.stack! : ERROR_MESSAGE;
    yield put(fetchConfigAppList.failure(msg));
  }
}

/**
 * 根据应用信息获取当前应用菜单列表
 *
 */
function* handleFetchMenuList(action: ReturnType<typeof fetchMenuList.request>): any {
  try {
    const appInfo = action.payload
    // 调用异步函数，向后台发送请求
    const res = yield call(fetchApi, "get", `permissionServer/user/leftnavgation/${appInfo.appId}`);

    // 请求结果调度
    if (res.result === 0 && res.data) {

      let list = res.data.records ? res.data.records : [];
      const { authorizationState: { menuList = [] } } = yield select()
      if (list.length) {
        list = list.map((ele: any) => {
          const obj = menuList.find((item: any) => item.appCode === ele.sourceAppCode)
          const bool = obj?.visitURL?.startsWith("http") || false;
          // const bool = !showSmartHRApplist.includes(ele.sourceAppCode)
          const preRouterUrl: string = getPreRouterUrl(ele.sourceAppCode)
          return {
            ...ele,
            externalUrl: bool ? obj.visitURL?.endsWith("/") ? obj.visitURL.substr(0, obj.visitURL.length - 1) : obj.visitURL + ele.visitURL + `?member_token=${TOKEN}&sourceAppCode=${obj.appCode}&appCode=${obj.appCode}` : "",
            visitURL: bool ? `/external/${ele.id}` : `${preRouterUrl}${ele.visitURL}`
          }
        });
      }
      // 将当前应用的菜单加到应用的子菜单里面
      for (let i = 0; i < menuList.length; i++) {
        if (list.length && menuList[i].appCode === list[0].sourceAppCode) {
          menuList[i].children = list
        }
      }
      // 当前应用没有给一个菜单访问权限，菜单数据里面去掉当前应用
      if(list.length === 0) {
        const index = menuList.findIndex((item: any) => item.appId === action.payload.appId)
        menuList.splice(index, 1)
      }
      yield put(fetchMenuList.success([...menuList]));

    } else {
      yield put(fetchMenuList.failure(res.detail));
    }
  } catch (err) {
    const msg = err instanceof Error ? err.stack! : ERROR_MESSAGE;
    yield put(fetchMenuList.failure(msg));
  }
}

/**
 * 获取数据字典
 */
function* handleFetchCommonNewMap(): any {
  try {
    // 调用异步函数，向后台发送请求
    const res = yield call(fetchApi, "get", "cossCenter/server/common/dataDict/querylist");
    // 请求结果调度
    if (res.result === 0 && res.data && res.data.extra) {
      yield put(fetchCommonNewMap.success(res.data.extra));
    } else {
      yield put(fetchCommonNewMap.failure(res.detail));
    }
  } catch (err) {
    const msg = err instanceof Error ? err.stack! : ERROR_MESSAGE;
    yield put(fetchCommonNewMap.failure(msg));
  }
}

/**
 * 执行登出操作
 */
function* handleFetchLogout(): any {
  try {
    // 调用异步函数，向后台发送请求
    const res = yield call(fetchApi, "get", "home-server/auth/logout");

    // 请求结果调度
    if (res.result === 0) {
      yield put(fetchLogout.success(res.data));
    } else {
      yield put(fetchLogout.failure(res.detail));
    }
  } catch (err) {
    const msg = err instanceof Error ? err.stack! : ERROR_MESSAGE;
    yield put(fetchLogout.failure(msg));
  }
}


/**监控，根据指定action类型，调用saga方法 */
function* authorizationSaga() {
  yield takeEvery(AuthorizationActionTypes.FETCH_USER_INFO, handleFetchUserInfo);
  yield takeEvery(AuthorizationActionTypes.FETCH_APP_LIST, handleFetchAppList);
  yield takeEvery(AuthorizationActionTypes.FETCH_CONFIG_APP_LIST, handleFetchConfigAppList);
  yield takeEvery(AuthorizationActionTypes.FETCH_MENU_LIST, handleFetchMenuList);
  yield takeEvery(AuthorizationActionTypes.FETCH_COMMON_NEW_MAP, handleFetchCommonNewMap);
  yield takeEvery(AuthorizationActionTypes.FETCH_LOGOUT, handleFetchLogout);
}
export default authorizationSaga;

import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Form, Button, Spin, Col, Card, Row, Modal, Steps, Popover, Select, Tooltip } from "antd"
import { connect } from "react-redux";
import { FormComponentProps } from "antd/lib/form";
import { RouteComponentProps } from "react-router-dom"
import { ApplicationState } from "store";
import { Dispatch } from "redux";
import moment from "moment";
import VhFormItem, { NodeType, ContentType } from "components/ms-common/vh-formItem";
import { VhSelect, VhTable, VhTools } from "msd";
import { CID } from "common/constants";
import { IMapType } from "common/types";
import { fetchLoading } from "store/common/actions";
import { LoadingType } from "store/common/types";
import { PageDataType, ProgressBoardAllCountType, ProgressBoardListType, ProgressBoardLogType } from "store/workbrench/types";
import { fetchDistinctCossServiceProgressList, fetchProgressBoardAllCount, fetchProgressBoardLog, fetchProgressBoardPageList } from "store/workbrench/actions";
import LookButton from "./LookButton";
import wait from "./assets/wait.png";
import doing from "./assets/doing.png";
import finish from "./assets/finish.png";
import service from "./assets/service.png";
import owner from "./assets/owner.png";
import "./Workbench.scss";

const mapStateToProps = ({ commonState, workBenchState, authorizationState }: ApplicationState) => ({
  loading: commonState.loading,
  progressBoardList: workBenchState.progressBoardList,
  progressBoardListPagination: workBenchState.progressBoardListPagination,
  progressBoardLog: workBenchState.progressBoardLog,
  progressBoardAllCount: workBenchState.progressBoardAllCount,
  distinctCossServiceProgressList: workBenchState.distinctCossServiceProgressList,
  boardTypeData: authorizationState.commonNewMap["coss.boardType"],
  boardStatusData: authorizationState.commonNewMap["coss.boardStatus"],
  menuList: authorizationState.menuList || []
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateLoading: (params: any) => dispatch(fetchLoading(params)),
  fetchProgressBoardPageList: (params: any) => dispatch(fetchProgressBoardPageList(params)),
  fetchProgressBoardLog: (params: any) => dispatch(fetchProgressBoardLog(params)),
  fetchProgressBoardAllCount: () => dispatch(fetchProgressBoardAllCount()),
  fetchDistinctCossServiceProgressList: (params: any) => dispatch(fetchDistinctCossServiceProgressList(params))
})

interface MsServiceProgressProps {
  /**页面loading状态 */
  loading: LoadingType;
  /** 服务看板列表数据 */
  progressBoardList: ProgressBoardListType[];
  /** 服务看板列表的分页数据 */
  progressBoardListPagination: PageDataType;
  /** 服务看板日志 */
  progressBoardLog: ProgressBoardLogType[];
  /** 服务进度看板统计数据 */
  progressBoardAllCount: ProgressBoardAllCountType;
  /** 菜单列表 */
  menuList: any[]
  /** 责任人下拉数据 */
  distinctCossServiceProgressList: any[];
  /** 服务类型 */
  boardTypeData: IMapType[];
  /** 服务状态 */
  boardStatusData: IMapType[];
  /** 服务看板分页列表 */
  fetchProgressBoardPageList: typeof fetchProgressBoardPageList;
  /** 服务看板日志 */
  fetchProgressBoardLog: typeof fetchProgressBoardLog;
  /** 服务看板统计数据 */
  fetchProgressBoardAllCount: typeof fetchProgressBoardAllCount;
  /** 责任方数据 */
  fetchDistinctCossServiceProgressList: typeof fetchDistinctCossServiceProgressList;
}

type AllProps = MsServiceProgressProps & FormComponentProps & RouteComponentProps;

const MsServiceProgress: React.FC<AllProps> = (props: AllProps) => {

  const {
    loading,
    form,
    history,
    menuList,
    progressBoardLog,
    progressBoardList,
    progressBoardListPagination,
    progressBoardAllCount,
    distinctCossServiceProgressList,
    boardStatusData,
    boardTypeData,
    fetchProgressBoardPageList,
    fetchProgressBoardLog,
    fetchProgressBoardAllCount,
    fetchDistinctCossServiceProgressList
  } = props

  const [isPreviewModalShow, setIsPreviewModalShow] = useState<boolean>(false)

  const onSearch = (data?: any) => {
    const formValue = form.getFieldsValue()
    const params: any = {
      ...formValue,
      startDate: formValue.createDate?.length > 0 ? moment(formValue.createDate[0]).format("YYYY-MM-DD") : "",
      endDate: formValue.createDate?.length > 0 ? moment(formValue.createDate[1]).format("YYYY-MM-DD") : "",
      modifyStartDate: formValue.modifyDate?.length > 0 ? moment(formValue.modifyDate[0]).format("YYYY-MM-DD") : "",
      modifyEndDate: formValue.modifyDate?.length > 0 ? moment(formValue.modifyDate[1]).format("YYYY-MM-DD") : "",
      pageIndex: data?.pageIndex || 1,
      pageSize: data?.pageSize || 25,
      cid: CID
    }
    delete params.createDate
    delete params.modifyDate
    fetchProgressBoardPageList(params)
  }

  useEffect(() => {
    onSearch()
    fetchProgressBoardAllCount()
  }, [])

  const onPageIndexChange = (current: number) => {
    onSearch({ pageIndex: current })
  }

  const onPageSizeChange = (_: number, size: number) => {
    onSearch({
      pageIndex: 1,
      pageSize: size
    })
  }

  const onDistinctSearch = useCallback((value: any) => {
    if (value.trim()) {
      fetchDistinctCossServiceProgressList({
        cid: CID,
        currentChargeName: value.trim()
      })
    }
  }, [])

  const nodeEle = useMemo(() => (
    <VhSelect
      showSearch={true}
      placeholder="请输入责任方名称"
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={VhTools.debounce(onDistinctSearch, 500)}
      notFoundContent={null}
      allowClear={true}
    >
      {distinctCossServiceProgressList?.length > 0 && distinctCossServiceProgressList.map((item: any) => (
        <Select.Option key={item.currentChargeCid} value={item.currentChargeCid}>
          {item.currentChargeName}
        </Select.Option>
      ))}
    </VhSelect>
  ), [distinctCossServiceProgressList])

  const content: ContentType[] = [
    { type: NodeType.rangePicker, key: "modifyDate", label: "更新时间", colProps: { span: 6 }, eleProps: { style: { width: "100%" } } },
    { type: NodeType.rangePicker, key: "createDate", label: "创建时间", colProps: { span: 6 }, eleProps: { style: { width: "100%" } } },
    {
      type: NodeType.select,
      key: "boardType",
      label: "服务类型",
      colProps: { span: 6 },
      options: [{ dataKey: "", dataValue: "全部" }].concat(boardTypeData ?? []),
      eleProps: { style: { width: "100%" }, placeholder: "请选择服务类型查询" }
    },
    {
      type: NodeType.reactNode,
      key: "currentChargeCid",
      label: "责任方",
      colProps: { span: 6 },
      formItemProps: { className: "statuItem" },
      nodeEle
    },
    {
      type: NodeType.select,
      key: "boardStatus",
      label: "状态",
      colProps: { span: 6 },
      options: [{ dataKey: "", dataValue: "全部" }].concat(boardStatusData ?? []),
      eleProps: {
        style: { width: "100%" },
        placeholder: "请选择状态查询"
      }
    },
  ]

  /** 返回省略显示 */
  const onSetToolTip = (text: string, direction: any = "top") => (
    (text || typeof (text) === "number")
      ? (
        <div className="tip-remark">
          <Tooltip title={text} placement={direction}>
            {text.length > 10 ? `${text.substr(0, 9)}...` : text}
          </Tooltip>
        </div>
      )
      : "--"
  )

  const columns: any[] = [
    { title: "更新时间", dataIndex: "modifyDate", key: "modifyDate", align: "left", render: (text: any) => text || "--" },
    { title: "创建时间", dataIndex: "createDate", key: "createDate", align: "left", render: (text: any) => text || "--" },
    { title: "服务类型", dataIndex: "boardType", key: "boardType", align: "left", render: (text: string) => (text || text === "0") ? VhTools.mappingFilter(boardTypeData, text.toString()) : "--" },
    { title: "服务描述", dataIndex: "boardDesc", key: "boardDesc", align: "left", width: 450, render: (text: string) => text || "--" },
    { title: "当前状态", dataIndex: "boardStatus", key: "boardStatus", align: "left", render: (text: string) => (text || text === "0") ? VhTools.mappingFilter(boardStatusData, text.toString()) : "--" },
    { title: "当前负责方", dataIndex: "currentChargeName", key: "currentChargeName", align: "left", width: 150, render: (text: string) => onSetToolTip(text) || "--" },
    {
      title: "操作", dataIndex: "opt", align: "left", key: "opt", render: (_: any, record: any) => (
        <>
          <LookButton style={{ marginRight: 10 }} record={record} menuList={menuList} />
          <Button type="link" onClick={() => {
            setIsPreviewModalShow(true)
            fetchProgressBoardLog(record.id)
          }}>
            进度概览
          </Button>
        </>
      )
    }
  ]

  /** 分页器 */
  const pagination = {
    pageSize: progressBoardListPagination.pageSize,
    current: progressBoardListPagination.pageIndex,
    total: progressBoardListPagination.total,
    pageSizeOptions: ["25", "50", "100"],
    showQuickJumper: true,
    showSizeChanger: true,
    showTotal: (total: number) => `共${total}条`,
    onChange: onPageIndexChange,
    onShowSizeChange: onPageSizeChange,
  }

  /** 搜索栏 */
  const searchForm = (
    <Card bordered={false}>
      <Form className="progressForm" layout="inline" autoComplete="off" style={{ overflow: "hidden" }}>
        <VhFormItem content={content} form={form} />
        <Col span={3}>
          <Form.Item style={{ marginLeft: 20 }}>
            <Button type="primary" style={{ marginRight: 50 }} onClick={onSearch}>查询</Button>
          </Form.Item>
        </Col>
      </Form>
    </Card>
  )

  /** 顶部数据 */
  const renderDataSatistics = () => {

    /** 统计数据 */
    const statisticData = [
      { id: 1, name: "待处理", num: progressBoardAllCount.pendingNum, image: wait },
      { id: 2, name: "待我方处理", num: progressBoardAllCount.byWzNum, image: owner },
      { id: 3, name: "待服务方处理", num: progressBoardAllCount.byServiceNum, image: service },
      { id: 4, name: "进行中", num: progressBoardAllCount.ongoingNum, image: doing },
      { id: 5, name: "已完成", num: progressBoardAllCount.completedNum, image: finish },
    ]

    return (
      <Row type="flex" className="statistics">
        {statisticData.map((item: any) => (
          <Col key={item.id}>
            <Card bordered={false}>
              <Row type="flex" justify="space-between">
                <div>
                  <div style={{ fontWeight: "bold", fontSize: 20, paddingBottom: 10 }}>{item.num}</div>
                  <div style={{ color: "#757B83" }}>{item.name}</div>
                </div>
                <img src={item.image} width={45} height={45} />
              </Row>
            </Card>
          </Col>
        ))}
      </Row>
    )
  }

  // 当前步骤
  const stepArr = useMemo(() => {
    const steps1 = [
      { name: "待服务方确认", value: 20 },
      { name: "已确认待付款", value: 30 },
      { name: "已付款待发放", value: 40 },
      { name: "发放中", value: 60 },
      { name: "已完成发放", value: 70 },
    ]
    const steps2 = [
      { name: "待服务方确认", value: 20 },
      { name: "打回", value: 80 }
    ]
    if (progressBoardLog?.length > 0) {
      if (progressBoardLog.some((item: ProgressBoardLogType) => item.operateType === 80)) {
        return steps2
      }
    }
    return steps1
  }, [progressBoardLog])

  // 当前进度
  const currentStep = useMemo(() => {
    if (progressBoardLog?.length > 0) {
      const maxOperateType = progressBoardLog.sort((a, b) => b.operateType - a.operateType)[0].operateType
      return stepArr.findIndex((item: any) => item.value === maxOperateType)
    }
    return 0
  }, [progressBoardLog])

  // 进度描述
  const renderProgressDesc = (step: number) => {
    if (progressBoardLog?.length > 0) {
      const curProgressLog = progressBoardLog.find((item: ProgressBoardLogType) => item.operateType === step)
      if (curProgressLog) {
        return (
          <>
            <div>{curProgressLog.creatorName}</div>
            <div>{curProgressLog.createTime}</div>
          </>
        )
      }
    }
    return null
  }

  return (
    <div className="serviceProgress">
      {renderDataSatistics()}
      <Card bordered={false}>
        <Popover trigger="click" content={searchForm} placement="bottomRight">
          <Button type="ghost" style={{ marginBottom: 20 }}>筛选条件</Button>
        </Popover>
        <Spin spinning={loading.showA} tip={loading.text}>
          <VhTable
            rowKey="id"
            dataSource={progressBoardList}
            columns={columns}
            showHeader={true}
            pagination={pagination}
            otherHeight={380}
          />
        </Spin>
      </Card>
      {isPreviewModalShow && (
        <Modal
          visible={true}
          title="进度概览"
          width={800}
          onCancel={() => setIsPreviewModalShow(false)}
          footer={[<Button key="cancel" onClick={() => setIsPreviewModalShow(false)}>取消</Button>]}
        >
          <Steps progressDot={true} current={currentStep} style={{ marginTop: 20 }}>
            {stepArr.map((item: any) => (
              <Steps.Step key={item.value} title={item.name} description={renderProgressDesc(item.value)} />
            ))}
          </Steps>
        </Modal>
      )}
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Form.create<AllProps>()(MsServiceProgress))
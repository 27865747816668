import React, { useState, useCallback, useEffect, memo } from "react";
import { LOADING_MESSAGE, TDAPP } from "common/constants";
import { Calendar, Icon, Dropdown, Menu, Spin, Empty, Card, Typography } from "antd";
import { RenderHeader } from "antd/lib/calendar/Header";
import moment from "moment";
import fetchApi from "utils/fetchApi";
import { ApplicationState } from "store";
import { useSelector } from "react-redux";
import { VhTools } from "msd";
import src from "./assets/empty_todo.png";
import "./Calendar.scss";

/**
 * 月份事项列表类型
 */
interface MonthListType {
  /** 当前月份的第几天 */
  day: number;
  /** 是否已完成 1#存在未完成、2#都已完成 */
  showStatus: number;
}

/**
 * 日期代办事项列表
 */
interface DateListType {
  id: number;
  showStatus: number;
  endDate: string;
  todoDesc: string;
  todoStatus: number;
  todoType: number;
}

const MenuItem = Menu.Item;
const f = "YYYY-MM-DD";

const mapState = ({ authorizationState }: ApplicationState) => ({
  todoTypeData: authorizationState.commonNewMap["coss.todoType"]
})

interface PropsOwn {
  /** 公司名称 */
  name: string;
  /** 姓名 */
  realName: string;
}

/**
 * 我的日程
 * @param props 
 * @returns 
 */
const VhCalendar = (props:PropsOwn) => {

  const { todoTypeData } = useSelector(mapState)

  /** 当前年份 */
  const [currentYear, setCurrentYear] = useState(moment().year());
  /** 当前月份 */
  const [currentMonth, setCurrentMonth] = useState(moment().month() + 1);
  /** 当前天数 */
  const [selectDay, setSelectDay] = useState(moment().date());
  /** 月份事项列表 */
  const [monthList, setMonthList] = useState<MonthListType[]>([]);
  /** 日期代办列表 */
  const [dateList, setDateList] = useState<DateListType[]>([]);
  /** 加载 */
  const [loading, setLoading] = useState({ show: true, text: "", delay: 0 });

  /** 设置补0格式 */
  const onFormatZero = useCallback((value: number) => value < 10 ? `0${value}` : value, []);

  /** 根据月份查询代办列表 */
  const onSearchMonthList = useCallback(async () => {
    setLoading({ show: true, text: LOADING_MESSAGE, delay: 1500 });
    const date = `${currentYear}-${onFormatZero(currentMonth)}-01`;
    const res = await fetchApi("get", `cossCenter/server/todoList/manager/queryByMonth?dateTime=${date}`);
    const list = res?.data || [];
    setMonthList(list);
    setLoading({ show: false, text: "", delay: 0 });
  }, [currentYear, currentMonth, onFormatZero]);

  /** 根据日期查询当天代办列表 */
  const onSearchDateList = useCallback(async () => {
    setLoading({ show: true, text: LOADING_MESSAGE, delay: 1500 });
    const date = `${currentYear}-${onFormatZero(currentMonth)}-${onFormatZero(selectDay)}`;
    const res = await fetchApi("get", `cossCenter/server/todoList/manager/queryByDay?dateTime=${date}`);
    const list = res?.data || [];
    setDateList(list);
    setLoading({ show: false, text: "", delay: 0 });
  }, [selectDay, currentYear, currentMonth, onFormatZero]);

  /** 查询月份查询代办列表 */
  useEffect(() => { onSearchMonthList() }, [onSearchMonthList]);

  /** 查询点击的日期代办列表 */
  useEffect(() => { onSearchDateList() }, [onSearchDateList]);

  /**
   * 重新渲染日历的每个日期
   * 
   * @param data 当前显示的每个日期，不是当月的日期隐藏。
   */
  const onDateCellRender = useCallback((data: moment.Moment) => {
    const d = data.date();
    const m = data.month() + 1;
    if (m !== currentMonth) return "";
    const todayCls = moment().format(f) === data.format(f) ? "today" : "";
    const seletCls = selectDay === data.date() ? "select" : "";
    const index = monthList.findIndex((ele: MonthListType) => `${ele.day}` === `${d}`);
    const isDoneCls = index < 0 ? "" : (monthList[index].showStatus === 2 ? "done" : "undone");
    return (
      <span className={`${todayCls} ${seletCls} ${isDoneCls}`}>
        {data.date()}
      </span>
    );
  }, [currentMonth, monthList, selectDay]);

  /**
   * 点击日期方法
   * 
   * @param date 点击的日期
   */
  const onSelect = useCallback((date: moment.Moment | undefined) => {
    setSelectDay(date?.date() || 1);
  }, []);

  /**
   * 设置不可显示的日期
   * 
   * @param date 日历中的每个日期
   */
  const onDisabledDate = useCallback((date: moment.Moment) => {
    const d = `${currentYear}-${onFormatZero(currentMonth)}`;
    return date < moment(d).startOf("month") || date > moment(d).endOf("month");
  }, [currentMonth, currentYear, onFormatZero]);

  /**
   * 设置显示节点
   * 
   * @param domNode 当前节点
   */
  const onSetNone = (domNode: any) => domNode.parentNode || window;

  /**
   * 自定义日历头部的回调方法。
   * 
   * @param obj 返回的对象
   */
  const onCustomHead = useCallback((obj: RenderHeader | undefined) => {

    /** 
     * 点击左右箭头切换月份
     * 
     * @param  type prev: 前一个月   next.后一个月
     */
    const setMonth = (type: string) => {
      const m = onFormatZero(currentMonth);
      const newMoment = type === "prev" ? moment(`${currentYear}-${m}`).subtract(1, "month") : moment(`${currentYear}-${m}`).add(1, "month")
      const newYear = newMoment.year();
      const newMonth = newMoment.month();
      const d = `${newYear}-${onFormatZero(newMonth + 1)}`;
      const lastDay = moment(d).daysInMonth();
      setCurrentYear(newYear);
      setCurrentMonth(newMonth + 1);
      if (selectDay > lastDay) {
        setSelectDay(lastDay);
      }
      if (obj?.onChange) {
        obj.onChange(newMoment);
      }
    }

    /**
     * 年、月选择方法
     * 
     * @param value 值
     */
    const onChangeYearOrMonth = (value: number) => {
      const isYear = `${value}`.length === 4;
      const newDate = isYear ? `${value}-${onFormatZero(currentMonth)}` : `${currentYear}-${onFormatZero(value)}`;
      const lastDay = moment(newDate).daysInMonth();
      isYear ? setCurrentYear(value) : setCurrentMonth(value);
      if (selectDay > lastDay) {
        setSelectDay(lastDay);
      }
      if (obj?.onChange) {
        obj.onChange(moment(newDate));
      }
    }

    /**
     * 设置年下拉选项
     * 
     * @returns 
     */
    const onSetYearOptions = () => {
      const yearOptions: JSX.Element[] = [];
      const start = moment().year() - 10;
      const end = moment().year() + 10;
      for (let i = start; i < end; i++) {
        yearOptions.push(
          <MenuItem key={i}>
            <p className={i === currentYear ? "active" : ""} onClick={() => onChangeYearOrMonth(i)}>{i}</p>
          </MenuItem>
        );
      }
      return yearOptions;
    }

    /**
     * 设置月下拉选项
     * 
     * @returns 
     */
    const setMonthOptions = () => {
      const monthOptions: JSX.Element[] = [];
      for (let i = 0; i < 12; i++) {
        monthOptions.push(
          <MenuItem key={i}>
            <p className={i + 1 === currentMonth ? "active" : ""} onClick={() => onChangeYearOrMonth(i + 1)}>{i + 1}</p>
          </MenuItem>
        );
      }
      return monthOptions;
    }

    return (
      <div className="head">
        <strong>我的日程</strong>
        <div>
          <Icon type="left" onClick={() => setMonth("prev")} />
          <Dropdown
            overlay={<Menu>{onSetYearOptions()}</Menu>}
            placement="bottomRight"
            overlayClassName="vh-calendar-dd"
            getPopupContainer={onSetNone}
          >
            <span>{currentYear}年</span>
          </Dropdown>
          &nbsp;
          <Dropdown
            overlay={<Menu>{setMonthOptions()}</Menu>}
            placement="bottomLeft"
            overlayClassName="vh-calendar-dd"
          >
            <span>{currentMonth}月</span>
          </Dropdown>
          <Icon type="right" onClick={() => setMonth("next")} />
        </div>
      </div>
    )
  }, [currentMonth, currentYear, onFormatZero, selectDay])

  /** 待办事项列表 */
  const onSetTodoList = (
    <div className="todo-list">
      {dateList.map((ele: DateListType) => (
        <div key={ele.id} className={"todo-item"}>
          <hr />
          {/* <Typography.Text type={ele?.todoStatus === 0 ? "danger" : (ele?.todoStatus === 1 ? "secondary" : "warning")}> */}
          <Typography.Text type="secondary">
            <span style={ ele?.todoStatus === 1 ? {textDecoration: "line-through", whiteSpace: "normal"} : { whiteSpace: "normal" }} title={ele?.todoDesc || ""}>
              [{ele?.todoType ? VhTools.mappingFilter(todoTypeData, ele.todoType.toString()) : "--"}]
              &nbsp;
              {ele?.todoDesc || ""}
            </span>
          </Typography.Text>
        </div>
      ))}
    </div>
  )


    const handleOnMouseEnter = (module: string) => {
      const { realName, name } = props;
      const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
      TDAPP.onEvent("进入工作台模块",`${name}_${realName}`,{"用户名":`${realName}_${timestamp}`,"公司" : `${name}_${timestamp}`,"模块": `${module}_${timestamp}`})
    }

    const handleOnMouseLeave = (module: string) => {
      const { realName, name } = props;
      const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
      TDAPP.onEvent("离开工作台模块",`${name}_${realName}`,{"用户名":`${realName}_${timestamp}`,"公司" : `${name}_${timestamp}`,"模块": `${module}_${timestamp}`})
    }

  return (
    <Card className="card-calendar" bordered={false}
      onMouseEnter={ handleOnMouseEnter.bind(VhCalendar,"我的日程") } onMouseLeave = { handleOnMouseLeave.bind(VhCalendar,"我的日程") }
    >
      <Spin tip={loading.text} spinning={loading.show} delay={loading.delay}>
        <Calendar
          fullscreen={false}
          dateFullCellRender={onDateCellRender}
          onSelect={onSelect}
          headerRender={onCustomHead}
          disabledDate={onDisabledDate}
          className="vh-calendar"
        />
        <div>
          <div className="add-todo">
            <strong>{moment(`${currentYear}-${onFormatZero(currentMonth)}-${onFormatZero(selectDay)}`).format("D号dddd")}</strong>
          </div>
          {dateList.length > 0 ? onSetTodoList : <Empty image={<img src={src} />} description="您当前暂无待办事项" />}
        </div>
      </Spin>
    </Card>
  )
}

export default memo(VhCalendar);
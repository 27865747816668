/* eslint-disable no-prototype-builtins */
import moment from "moment";
import { TOKEN } from "common/constants"
import { VhValidator } from "msd";

/**
 * 工具类
 *
 * @export
 * @class Tools
 */
const Tools = {

  supportsHistory: () => {
    const ua = window.navigator.userAgent;
    if ((ua.indexOf("Android 2.") !== -1 || ua.indexOf("Android 4.0") !== -1) && ua.indexOf("Mobile Safari") !== -1 && ua.indexOf("Chrome") === -1 && ua.indexOf("Windows Phone") === -1) {
      return false;
    }
    return window.history && "pushState" in window.history;
  },

  /**
   * 对象深拷贝
   *
   * @param {*} obj
   * @returns objCopy
   */
  deepCopy: (obj: any) => {
    return JSON.parse(JSON.stringify(obj));
  },

  /**
   * 清除obj中的undefined，null，"", NAN
   *
   * @param {*} obj
   * @returns 清除空值后的对象
   */
  clearObj: (obj: any) => {
    const o = { ...obj };
    for (const key in o) {
      if (o.hasOwnProperty(key) && !o[key] && o[key] !== 0) {
        delete o[key];
      }
    }
    return o;
  },

  /**
   * 在数组指定位置插入元素
   *
   * @param {*} obj
   * @returns 清除空值后的对象
   */
  insertArray: (array: any[], index: number, item: any) => {
    array.splice(index, 0, item);
  },

  /**
   * 在数组指定位置删除元素
   *
   * @param {*} obj
   * @returns 清除空值后的对象
   */
  deleteArray: (array: any[], index: number) => {
    array.splice(index, 1);
  },

  /**
   * 将json拼接为请求参数字符串
   *
   * @param {*} params 参数对象
   * @returns 参数字符串
   */
  appendParams: (url: string, params: any) => {
    if (!url) {
      return "";
    }

    let str = "";
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        str += (key + "=" + params[key] + "&");
      }
    }
    if (str) {
      str = url + "?" + str.substr(0, str.length - 1);
    } else {
      str = url;
    }
    return str;
  },

  /**
   * 下载文件
   *
   * @param {*} url 文件地址
   */
  downloadFile: (url: string) => {
    // const elemIF = document.createElement("iframe");
    // document.body.appendChild(elemIF);

    // const elemForm = document.createElement("form");
    // elemForm.method = "get";
    // elemForm.action = url;
    // elemIF.append(elemForm);
    // elemForm.submit();

    // elemIF.onload = () => {
    //   document.body.removeChild(elemIF);
    // };
    const elemA = document.createElement("a");
    elemA.href = url;
    elemA.download = "";
    document.body.appendChild(elemA);

    elemA.click();
    elemA.onload = () => {
      document.body.removeChild(elemA);
    };
  },

  /**
   * 下载Excel
   * 
   * @param method 
   * @param url 
   * @param data 
   */
  downloadExcel: (method: string, url: string, data?: any, callback?: () => void) => {
    const xhr = new XMLHttpRequest();
    const html5Saver = (blob: any, name: string | null) => {
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style.display = "none";
      const path = window.URL.createObjectURL(blob);
      a.href = path;
      a.download = name ?? "";
      a.click();
      document.body.removeChild(a);
      if (callback) {
        callback()
      }
    }

    const saveBlob = (response: any, name: string) => {
      navigator.msSaveBlob
        ? navigator.msSaveBlob(response, name)
        : html5Saver(response, name);
    }

    xhr.open(method, url, true);
    xhr.responseType = "blob";
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", "Bearer " + TOKEN);
    xhr.onload = (event: any) => {
      const res = event.target || event.srcElement;
      if (res.status === 200) {
        let fileName = "";
        const disposition = xhr.getResponseHeader("Content-Disposition");
        if (disposition && disposition.indexOf("attachment") !== -1) {
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          const matches = filenameRegex.exec(disposition);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/['"]/g, "");
          }
        }
        const blob = new Blob([res.response], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        });
        saveBlob(blob, fileName);
      }
    };
    xhr.send(method === "post" && data ? JSON.stringify(data) : "")
  },

  /**
   * 根据名称过滤下拉列表
   *
   * @param {string} name
   * @returns
   */
  filterOption: (input: string, option: any) => {
    return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  },

  /**
   * 根据名称获取cookie
   *
   * @param {string} name
   * @returns
   */
  getCookie: (name: string) => {
    const reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    const arr = document.cookie.match(reg);
    if (arr) {
      return unescape(arr[2]);
    } else {
      return null;
    }
  },

  /**
   * 设置cookie
   *
   * @param {string} name cookie名称
   * @param {string} value cookie值
   * @param {date} 过期时间
   * @returns
   */
  setCookie: (name: string, value: string, date: Date, url: string) => {
    if (Tools.getCookie(name)) {
      Tools.delCookie(name);
    }
    const domain = process.env.REACT_APP_ENV === "local" ? window.location.hostname : url;
    document.cookie = name + "=" + escape(value) + ";expires=" + date.toUTCString() + ";path=/;domain=" + domain;
  },

  /**
   * 删除cookie
   *
   * @param {string} name cookie名称
   * @returns
   */
  delCookie: (name: string) => {
    const date = new Date();
    const value = Tools.getCookie(name);
    date.setTime(date.getTime() - 1);
    if (value != null) {
      const domain = process.env.REACT_APP_ENV === "local" ? window.location.hostname : "smarthr.cn";
      const domainHome = process.env.REACT_APP_ENV === "local" ? window.location.hostname : "viphrm.com";
      document.cookie = name + "=" + value + ";expires=" + date.toUTCString() + ";path=/;domain=" + domain;
      document.cookie = name + "=" + value + ";expires=" + date.toUTCString() + ";path=/;domain=" + domainHome;
    }
  },

  /**
   * 获取初始化分页对象
   *
   * @returns 初始化分页对象
   */
  getInitPagination: () => {
    return {
      pageSize: 25,
      current: 1,
      total: 0
    };
  },

  /**
   * 根据键值获取名称
   *
   * @param {any[]} list 列表
   * @param {string} keyValue 键值
   * @param {string | undefined} keyName 键名
   * @param {string | undefined} valueName 值名
   * @returns
   */
  mappingFilter: (list: any[], keyValue: string | number, keyName?: string, valueName?: string) => {
    if (!list || !list.length) {
      return "";
    }
    const obj = list.find((item: any) => item[(keyName ? keyName : "dataKey")] === keyValue);
    if (obj) {
      return obj[(valueName ? valueName : "dataValue")];
    } else {
      return "";
    }
  },

  /**
   * 事件防抖动事件
   *
   * @param {F} fn type is Arrow Function
   * @param {number} delay
   * @returns
   */
  debounce: <F extends (...params: any[]) => void>(fn: F, delay: number): F => {
    let timeoutId: number;
    return function (this: any, ...args: any[]) {
      clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => fn.apply(this, args), delay);
    } as F;
  },

  /**
   * 获取对象数组的每个元素的指定属性值，组成数组返回
   * @param {array} array
   * @param {string} key
   * @returns {Array}
   */
  arrayPluck: (array: any[], key: string) => {
    const rs: any[] = [];
    array.forEach(obj => obj[key] && rs.push(obj[key]));
    return rs;
  },

  // 根据身份证号码计算年龄
  getAgeByIdCard: (cardNo: string) => {
    const len = (cardNo + "").length;
    if (len === 0) {
      return 0;
    } else {
      if ((len !== 15) && (len !== 18)) // 身份证号码只能为15位或18位其它不合法
      {
        return 0;
      }
    }
    let strBirthday = "";
    if (len === 18)  //  处理18位的身份证号码从号码中得到生日和性别代码
    {
      strBirthday = cardNo.substr(6, 4) + "/" + cardNo.substr(10, 2) + "/" + cardNo.substr(12, 2);
    }
    if (len === 15) {
      strBirthday = "19" + cardNo.substr(6, 2) + "/" + cardNo.substr(8, 2) + "/" + cardNo.substr(10, 2);
    }
    // 时间字符串里，必须是“/”
    const birthDate = new Date(strBirthday);
    const nowDateTime = new Date();
    let age = nowDateTime.getFullYear() - birthDate.getFullYear();
    // 再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
    if (nowDateTime.getMonth() < birthDate.getMonth() || (nowDateTime.getMonth() === birthDate.getMonth() && nowDateTime.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  },

  // 小数相加
  addNum: (num1: number, num2: number) => {
    let sq1 = 0;
    let sq2 = 0;
    try {
      sq1 = num1.toString().split(".")[1].length;
    } catch (e) {
      sq1 = 0;
    }
    try {
      sq2 = num2.toString().split(".")[1].length;
    } catch (e) {
      sq2 = 0;
    }
    const multiple = Math.pow(10, Math.max(sq1, sq2) + 1);
    return (num1 * multiple + num2 * multiple) / multiple;
  },

  /**
   * 金额转千分位格式，带两位小数
   * @param  num   金额
   */
  thousandSeparator: (num: string | number, noFloat?: boolean) => {
    if (isNaN(+num)) {
      return "";
    }
    const str: any = (+num).toFixed(2);
    const arr = str.split(".");
    return `${arr[0].replace(/\d{1,3}(?=(\d{3})+(\.\d*)?$)/g, "$&,")}${noFloat ? "" : `.${arr[1]}`}`;
  },
  /**
   * 拼接数字数组为指定日期格式
   * [202005,202006] -> [moment,moment]
   */
  joinNumArrayToMoment: (begin: number, end: number) => {
    const amountMonthBeginForamtted = `${String(begin).slice(0, 4)}-${String(begin).slice(4, 6)}-01`;
    const amountMonthEndForamtted = `${String(end).slice(0, 4)}-${String(end).slice(4, 6)}-01`;
    return [moment(amountMonthBeginForamtted, "YYYY-MM-DD"), moment(amountMonthEndForamtted, "YYYY-MM-DD")];
  },
  /**
   * 金额格式化
   * 
   */
  inputNumberMoneyFormatter: (value: any) => `¥ ${value}元`,
  /**
   * 金额转换回数字
   * 
   */
  inputNumberMoneyParser: (value: any) => value.replace(/¥\s?|(,*)/g, "").replace(/元/, ""),
  /**
   * 费率格式化
   * 
   */
  inputNumberRateFormatter: (value: any) => `${value} %`,
  /**
   * 费率转换回数字
   * 
   */
  inputNumberRateParser: (value: any) => value.replace("%", ""),
  /**
   * 获取地址中指定的参数
   * @param   name   参数名称
   */
  getUrlParam: (name: string) => {
    let value = "";
    const url = decodeURIComponent(window.location.href);
    const list = url.split("?");
    if (list[1]) {
      const arr = list[1].split("&");
      for (const ele of arr) {
        const arrs = ele.split("=");
        if (arrs[0] === name) {
          value = arrs[1];
          break;
        }
      }
    }
    return value;
  },
  /**
   * 通过原生ajax下载文件
   * @param    url        地址
   * @param    fileName   文件名
   */
   downloadFileWithXHR: (url: string, fileName?: string) => {
    try {
      if (window.navigator.userAgent.indexOf("MSIE 9.0") > -1) {
        const elemA = document.createElement("a");
        elemA.href = url;
        elemA.download = fileName || "文件下载";
        document.body.appendChild(elemA);
        elemA.click();
        elemA.onload = () => {
          document.body.removeChild(elemA);
        };
      } else {
        const xhr = new XMLHttpRequest();
        const html5Saver = (blob: any, name: string) => {
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style.display = "none";
          const path = window.URL.createObjectURL(blob);
          a.href = path;
          a.download = name;
          a.click();
          document.body.removeChild(a);
        }
        const saveBlob = (response: any, name: string) => {
          navigator.msSaveBlob
          ? navigator.msSaveBlob(response, name)
          : html5Saver(response, name);
        }
        xhr.open("get", url, true);
        xhr.responseType = "blob";
        xhr.onload = (event: any) => {
          const e = event.target || event.srcElement;
          if (e.status === 200) {
            saveBlob(e.response, fileName || "");
          }
        };
        xhr.onerror = (e: any) => {
          console.log(e);
        };
        xhr.send();
      }
    } catch (err) {
      console.log(err);
    }
  },
  /**
   * 金额转中文（例如：12300000890）
   * @param   num    金额
   *
   * 1-  金额的长度取余数，比如，长度11除以4余3
   * 2-  先截取余数，把123存入数组
   * 3-  截取余数后，再每4位截取，最终的数组为["123", "0000", "0890"]
   * 4-  0000不处理
   * 5-  最后一位是0（比如 0340），要在最后加”零“
   * 6-  当前数字和下一个数字都为0，不匹配中文和单位，否则当前为0的只匹配中文
   * 7-  由于bool，最终会出现”零零“的情况，比如 ["130", "0022"]这种情况
   * 8-  去掉最后一个零
   * 9-  finalStr如果为空（金额只有角、分的情况），直接赋值”零”
   * 10- 没有角分，加上”整“
   * 11- 00不处理
   * 12- 角分只取前两位
   * 13- 处理只有角、分的情况（比如0.45、0.7）
   */
   transNumberToCH: (num: string | number) => {
    const numberCH = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    const unitSmall= ["", "拾", "佰", "仟"];
    const unitLarge = ["", "万", "亿"];
    const unitTiny = ["角", "分"];
    const unitBasic = "圆";
    const unitFinal = "整";
    const str = `${num}`;
    if (!str.trim() || str.substr(str.length - 1) === "." || isNaN(+str) || (+str) < 0 || (+str) > 999999999999.99) {
      return "";
    }
    const arr = str.replace(/,/g, "").split(".");
    let leftNum = arr[0];
    let finalStr = "";
    let len = leftNum.length;
    const first = len % 4;  // 1
    const leftArr = [];
    if (first) { // 2
      leftArr.push(leftNum.substring(0, first));
      leftNum = leftNum.substring(first);
      len = leftNum.length;
    }
    for (let i = 0; i < len; i = i + 4) { // 3
      leftArr.push(leftNum.substring(i, i + 4));
    }
    const mLen = leftArr.length;
    leftArr.forEach((ele: any, i: number) => {
      if (+ele) { // 4
        const eleLen = ele.length;
        const bool = ele.substring(ele.length - 1) === "0"; // 5
        for (let j = 0; j < eleLen; j++) { // 6
          finalStr += !+ele[j] && !+ele[j + 1] ? "" : `${ numberCH[ele[j]] }${ !+ele[j] ? "" : unitSmall[(eleLen - (j + 1))] }`;
        }
        finalStr += unitLarge[mLen - (i + 1)];
        finalStr += (bool ? "零" : "");
      }
    });
    finalStr = finalStr.replace(/零零/g, "零"); // 7
    if (finalStr.substring(finalStr.length - 1) === "零") { // 8
      finalStr = finalStr.substring(0, finalStr.length - 1);
    }
    finalStr = (finalStr || "零") + unitBasic; // 9
    if (!arr[1]) { // 10
      return finalStr += unitFinal;
    }
    const rightNum:any = arr[1];
    const rightLen = rightNum.length;
    let rightStr = "";
    if (+rightNum) { // 11
      for (let i = 0; i < rightLen; i++) {
        rightStr += `${numberCH[rightNum[i]]}${!+rightNum[i] ? "" : unitTiny[i]}`;
        if (i === 1) { // 12
          break;
        }
      }
    }
    finalStr += rightStr.replace(/角零/g, "角");
    if (finalStr.length > 2 && finalStr !== "零圆整" && finalStr.substr(0, 2) === "零圆") { // 13
      finalStr = finalStr.substr(2);
    }
    return finalStr;
  }
}

export default Tools;

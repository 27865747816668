import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ICompany, IUser, MenuType } from "common/types";
import { Icon, Layout, Menu } from "antd";
import VhIcon from "components/vh-icon/VhIcon";
import src from "./assets/default-logo.png";
import "./aVhMenu.scss";
import { homeUrl, TDAPP } from "common/constants"
import Tools from "utils/tools";

import {ReactComponent as Dashbroad} from "./assets/dashbroad.svg";
import {ReactComponent as DashbroadSelect} from "./assets/dashbroad-select.svg";
import {ReactComponent as Dispatch} from "./assets/dispatch.svg";
import {ReactComponent as DispatchSelect} from "./assets/dispatch-select.svg";
import {ReactComponent as Salary} from "./assets/salary.svg";
import {ReactComponent as SalarySelect} from "./assets/salary-select.svg";
import moment from "moment";

interface PropsOwn {
  /** 公司logo */
  companyLogo: string;
  /** 菜单列表 */
  menu: MenuType[];
  /** 标题 */
  title: string;
  /** 展开/收缩状态 */
  collapse: boolean;
  /** 公司cid */
  companyCid: string;
  openKeysList: any[];
  realName: string;
  name: string;
}

type AllProps = PropsOwn;

const { Sider } = Layout;
const { SubMenu, Item } = Menu;
const initMenu = {
  id: 0,
  groupName: "",
  groupIconName: "",
  functionName: "",
  functionCode: "",
  iconName: "",
  visitURL: "",
  sourceAppCode: "",
  targetAppCode: "",
  externalUrl: "",
  params: "",
  children: [],
}

const VhMenu = (props: AllProps) => {
  const { menu, collapse, companyLogo, openKeysList } = props;
  const historyObj = useHistory();
  const locationObj = useLocation();
  const [menuList, setMenuList] = useState<MenuType[]>([]);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  /**
   * 获取菜单中包含当前路由的所有数组，但只取最后一个。
   * menu是通过接口得到，所以会渲染一次，根路由进来时返回结果为null。
   * 路由变更，pathname就会变更，所以会渲染多次。
   */
  const getCurrentRouteObj = useCallback((): MenuType | null => {
    const path = locationObj.pathname;
    const matchUrlArr: MenuType[] = menuList.filter((ele: MenuType) => path.startsWith(`${ele.visitURL}`));
    return matchUrlArr.length ? matchUrlArr[matchUrlArr.length - 1] : null;
  }, [locationObj.pathname, menuList]);

  useEffect(() => {
    setOpenKeys(openKeysList)
  }, [openKeysList])

  useEffect(() => {
    if(menu?.length) {
      // 将menu数组平铺为一维数组
      const arr = menu.reduce((res: any[], current: any) => {
        if(current.children?.length) {
          res = res.concat(current.children)
        }else {
          res.push(current)
        }
        return res
      }, [])
      setMenuList(arr);
    }
  }, [menu])

  useEffect(() => {
    const currentRoute = getCurrentRouteObj();
    if(currentRoute) {
      setSelectedKeys(currentRoute && currentRoute.functionCode ? [currentRoute.functionCode] : []);
      const res = menu.find((item: any) => item.appCode === currentRoute.sourceAppCode)
      setOpenKeys(res && res.groupName ? [res.groupName, ...openKeys] : [...openKeys]);
    }

  }, [location.pathname, menu, menuList])

  /**
   * 点击菜单方法
   * @param obj 菜单对象
   */
  const onMenuClick = (obj: any) => {
    const { name, realName } = props;
    const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
    
    const menuObj: MenuType | undefined = menuList.find((ele: MenuType) => obj.key === ele.functionCode);
    if (menuObj) {
      TDAPP.onEvent("点击左侧菜单",`${name}_${realName}`,{"用户名":`${realName}_${timestamp}`,"公司" : `${name}_${timestamp}`,"应用名称":`${openKeys[0]}_${timestamp}`,"模块名称": `${menuObj.functionName}_${timestamp}`})
      historyObj.push(`${menuObj.visitURL}`);
    }
  }

  /**
   * 展开/收起菜单
   * @param value 
   */
  const onOpenChange = (value: string[]) => {
    setOpenKeys(value);
  }

  const onGoHome = () => {
    Tools.delCookie("ms_member_token")
    Tools.delCookie("ms_cid")
    window.location.href = homeUrl
  }

  const MyIcon = Icon.createFromIconfontCN({
    scriptUrl: "//at.alicdn.com/t/font_1137289_eaexo1v91d.js", // 在 iconfont.cn 上生成
  });

  const setMenuListDom = (list: MenuType[], showIcon = true) => {
    return list.map((ele: MenuType) =>
      ele.children?.length
        ? (
          <SubMenu
            key={ele.groupName}
            title={
              <span style={{display: "flex", alignItems: "center"}}>
                { ele.functionCode === "30000" && <Icon style={{fontSize: 17}} component={openKeys.indexOf(ele.groupName) > -1 ? DashbroadSelect : Dashbroad}/> }
                { ele.functionCode === "30001" && <Icon style={{fontSize: 17}} component={openKeys.indexOf(ele.groupName) > -1 ? SalarySelect : Salary}/> }
                { ele.functionCode === "30002" && <Icon style={{fontSize: 17}} component={openKeys.indexOf(ele.groupName) > -1 ? DispatchSelect : Dispatch}/> }
                <span>{ele.groupName}</span>
              </span>
            }
          >
            {setMenuListDom(ele.children, false)}
          </SubMenu>
        )
        : (
          <Item key={ele.functionCode}>
            <span>{ele.functionName}</span>
          </Item>
        )
    )
        }
  /**
   * 跳转首页
   */
  const onGoToMainPage = () => {
    // window.location.href = companyCid === "288572" ? mossAddr : homeAddr;
  }

  /**
   * 注：
   * 如果不考虑回退/前进按钮，那么Menu组件只需设置defaultSelectedKeys、defaultOpenKeys，
   * 后续所有操作都由该组件接管，而这两个default只在首次赋值时生效，
   * 所以要用menuList做个判断。
   * 
   * 如果考虑回退/前进按钮，那么只能设置selectdKeys、openKeys
   * 
   * ！！！特别注意！！！，新项目(采用antd4 + react17)，使用defaultSelectedKeys后，在切换显示模式时，头部会早于其他页面进行颜色切换，
   * 老项目(也就是本项目采用antd3x + react16.14)在切换模式时头部和其他组件是同时切换的，
   * 所以新项目暂时改用selectedKeys，并在onMenuClick中增加setSelectedKeys([obj.key]);
   */
  return (
    <Sider className="smarthr-sider" style={{ backgroundColor: "#fff" }} trigger={null} collapsible={true} collapsed={collapse}>
      {/* {!collapse
        ? (
          <span style={{ height: 60, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img style={{ height: 40 }} src={companyLogo || src} onClick={onGoToMainPage} />
          </span>
        )
        : null
      } */}
      {menuList?.length
        ? <Menu
          selectedKeys={selectedKeys}
          openKeys={openKeys}
          mode="inline"
          theme="light"
          onClick={onMenuClick}
          onOpenChange={onOpenChange}
          style={{ marginTop: 10, padding: "0px 10px", border: 0, height: "calc(100% - 70px)",overflowX: "hidden", overflowY: "auto" }}
        >
          {setMenuListDom(menu, false)}
        </Menu>
        : null
      }
      {!collapse && <span className="to-home-viphrm" onClick={onGoHome}>返回旧版</span>}
    </Sider>
  );
}

export default VhMenu;

import React, { useEffect } from "react";
import { Form, Button, Spin, Popconfirm, Col, Modal, message } from "antd"
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { FormComponentProps } from "antd/lib/form";
import { ApplicationState } from "store";
import { Dispatch } from "redux";
import moment from "moment";
import VhFormItem, { NodeType, ContentType } from "components/ms-common/vh-formItem";
import VhTable from "components/ms-common/vh-table/index";
import { LoadingType } from "store/common/types";
import { fetchMessageList, fetchUpdateMessageStatus } from "store/workbrench/actions";
import { MessageListType, PageDataType } from "store/workbrench/types";
import { CID } from "common/constants";
import { IMapType } from "common/types";
import { VhTools } from "msd";
import "./Workbench.scss";

const mapStateToProps = ({ commonState, workBenchState, authorizationState }: ApplicationState) => ({
  loading: commonState.loading,
  messageList: workBenchState.messageList,
  messageListPagination: workBenchState.messageListPagination,
  messageStatusData: authorizationState.commonNewMap["coss.messageStatus"],
  messageTypeData: authorizationState.commonNewMap["coss.messageType"],
  menuList: authorizationState.menuList || []
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchMessageList: (params: any) => dispatch(fetchMessageList(params)),
  fetchUpdateMessageStatus: (params: any) => dispatch(fetchUpdateMessageStatus(params)),
})

interface MsNewMessageProps {
  /**页面loading状态 */
  loading: LoadingType;
  /** 菜单列表 */
  menuList: any[]
  /** 消息列表数据 */
  messageList: MessageListType[];
  /** 消息列表的分页数据 */
  messageListPagination: PageDataType;
  /** 消息状态数据字典 */
  messageStatusData: IMapType[];
  /** 消息类型数据字典 */
  messageTypeData: IMapType[];
  /** 消息分页列表 */
  fetchMessageList: typeof fetchMessageList;
  /** 更新消息状态 */
  fetchUpdateMessageStatus: typeof fetchUpdateMessageStatus;
}

type AllProps = MsNewMessageProps & FormComponentProps & RouteComponentProps

const MsNewMessage: React.FC<AllProps> = (props) => {

  const {
    loading,
    menuList,
    form,
    messageList,
    messageListPagination,
    messageStatusData,
    messageTypeData,
    fetchMessageList,
    fetchUpdateMessageStatus
  } = props

  const onSearch = (data?: any) => {
    const formValue = form.getFieldsValue()
    const params: any = {
      ...formValue,
      startDate: formValue.createDate?.length > 0 ? moment(formValue.createDate[0]).format("YYYY-MM-DD") : "",
      endDate: formValue.createDate?.length > 0 ? moment(formValue.createDate[1]).format("YYYY-MM-DD") : "",
      pageIndex: data?.pageIndex || messageListPagination.pageIndex || 1,
      pageSize: data?.pageSize || messageListPagination.pageSize || 25,
      cid: CID
    }
    delete params.createDate
    fetchMessageList(params)
  }

  useEffect(() => {
    onSearch()
  }, [])

  const content: ContentType[] = [
    {
      type: NodeType.rangePicker,
      key: "createDate",
      label: "发送时间",
      colProps: { span: 6 },
      eleProps: { style: { width: "100%" } }
    },
    {
      type: NodeType.input,
      key: "messageDesc",
      label: "消息内容",
      colProps: { span: 6 },
      eleProps: {
        style: { width: "100%" },
        placeholder: "请输入消息内容查询"
      }
    },
    {
      type: NodeType.select,
      key: "messageStatus",
      label: "状态",
      colProps: { span: 4 },
      options: [{ dataKey: "", dataValue: "全部" }].concat(messageStatusData ?? []),
      formItemProps: { className: "statuItem" },
      eleProps: {
        style: { width: "100%" },
        placeholder: "请选择状态查询"
      }
    },
  ]

  // 查看
  const onCheck = (record: any) => {
    let menuItem: any = null
    menuList.map((item: any) => {
      if (item.appCode === record.appId && item.children?.length) {
        menuItem = item.children.find((val: any) => val.functionCode === record.functionCode)
      }
    })
    if (menuItem) {
      window.history.pushState({ paramInfo: record.paramInfo ? JSON.parse(record.paramInfo) : null }, "", menuItem.visitURL)
    }
  }

  /** 查看消息 */
  const onCheckMessage = async (record: MessageListType) => {
    // 未读消息更新成已读
    if (+record.messageStatus === 0) {
      await fetchUpdateMessageStatus({
        params: {
          messageStatus: 1,
          id: record.id,
          cid: CID
        }
      })
    }
    if (+record.messageType === 3) {
      Modal.info({
        title: "系统公告",
        content: (
          <div>
            <p>{record.messageDesc}</p>
          </div>
        )
      })
    } else {
      onCheck(record)
    }
  }

  /** 删除消息 */
  const onDeleteMessage = (record: MessageListType) => {
    fetchUpdateMessageStatus({
      params: {
        id: record.id,
        cid: CID,
        isDel: 1
      },
      callback: () => {
        message.success("删除成功！")
        onSearch()
      }
    })
  }

  const columns: any[] = [
    { title: "发送时间", align: "left", dataIndex: "createDate", key: "createDate", render: (text: any) => text || "--" },
    { title: "类型", align: "left", dataIndex: "messageType", key: "messageType", render: (text: any) => text ? VhTools.mappingFilter(messageTypeData, text.toString()) : "--" },
    { title: "消息内容", align: "left", dataIndex: "messageDesc", key: "content", width: 500, render: (text: any) => text || "--" },
    { title: "当前状态", align: "left", dataIndex: "messageStatus", key: "status", render: (text: any) => (text || text === 0) ? VhTools.mappingFilter(messageStatusData, text.toString()) : "--" },
    {
      title: "操作", align: "left", dataIndex: "opt", key: "opt", render: (_: any, record: MessageListType) => (
        <>
          <Button type="link" style={{ marginRight: 10 }} onClick={() => onCheckMessage(record)}>查看</Button>
          <Popconfirm title="确定删除？" onConfirm={() => onDeleteMessage(record)}>
            <Button type="link">删除</Button>
          </Popconfirm>
        </>
      )
    },
  ]

  const onPageIndexChange = (current: number) => {
    onSearch({ pageIndex: current })
  }

  const onPageSizeChange = (_: number, size: number) => {
    onSearch({ pageIndex: 1, pageSize: size })
  }

  const pagination = {
    pageSize: messageListPagination.pageSize,
    current: messageListPagination.pageIndex,
    total: messageListPagination.total,
    pageSizeOptions: ["25", "50", "100"],
    showQuickJumper: true,
    showSizeChanger: true,
    showTotal: (total: number) => `共${total}条`,
    onChange: onPageIndexChange,
    onShowSizeChange: onPageSizeChange,
  }

  return (
    <div className="newMessage">
      <Spin spinning={loading.showA}>
        <Form className="messageForm" layout="inline" autoComplete="off" style={{ overflow: "hidden" }}>
          <VhFormItem content={content} form={form} />
          <Col span={3}>
            <Form.Item style={{ marginLeft: 20 }}>
              <Button type="primary" style={{ marginRight: 50 }} onClick={onSearch}>查询</Button>
            </Form.Item>
          </Col>
        </Form>
        <VhTable
          key="message"
          rowKey="id"
          bordered={false}
          showHeader={true}
          dataSource={messageList}
          columns={columns}
          pagination={pagination}
          otherHeight={100}
        />
      </Spin>
    </div>
  )
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form.create<AllProps>()(MsNewMessage)))
import { Reducer } from "redux";
import { MyAgencyType, MyAgencyStateType, initPageData } from "./types";

// 初始化state
export const initCommonState: MyAgencyStateType = {
  agencyList: [],
  pageData: initPageData,
};

const myAgencyReducer: Reducer<MyAgencyStateType> = (state = initCommonState, action) => {
  switch (action.type) {
    // 更新单个state
    case MyAgencyType.FETCH_UPDATE_MYAGENCY_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return { ...state };
    }
  }
}

export { myAgencyReducer }

import { combineReducers, Dispatch, Action, AnyAction } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { all, fork } from "redux-saga/effects";
import { History } from "history";

// 登陆验证模块
import { AuthorizationState } from "./authorization/types";
import { authorizationReducer } from "./authorization/reducer";
import authorizationSaga from "./authorization/sagas";

// 公共模块
import { CommonState } from "./common/types";
import { commonReducer } from "./common/reducer";
import commonSaga from "./common/sagas";

// 群发工资条模块
import { MyAgencyStateType } from "./my-agency/types"
import { myAgencyReducer } from "./my-agency/reducer"
import myAgencySaga from "./my-agency/sagas"

// 工作台模块
import { WorkBenchStateType } from "./workbrench/types"
import { workBenchReducer } from "./workbrench/reducer"
import workBrenchSaga from "./workbrench/sagas"

/**
 * 顶层state
 *
 * @export
 * @interface ApplicationState
 */
export interface ApplicationState {
  router: RouterState;

  /********** 登录以及权限验证模块 **********/
  authorizationState: AuthorizationState;
  /**公共模块 */
  commonState: CommonState;
  /**我的待办模块 */
  myAgencyState: MyAgencyStateType;
  /**工作台模块 */
  workBenchState: WorkBenchStateType;
}

/**添加属性给react组件，此属性默认传递给connect()方法 */
export interface ConnectedReduxProps<A extends Action = AnyAction> {
  dispatch: Dispatch<A>
}

/**当action被调度, Redux根据名称去匹配reducer，然后更新相应的state */
export const rootReducer = (history: History<any>) => combineReducers<ApplicationState>({
  router: connectRouter(history),

  /********** 登录以及权限验证模块 **********/
  authorizationState: authorizationReducer,
  // 公共模块
  commonState: commonReducer,
  // 我的待办模块
  myAgencyState: myAgencyReducer,
  // 工作台模块
  workBenchState: workBenchReducer
});

// 合并saga
export function* rootSaga() {
  yield all([
    /********** 登录以及权限验证模块 **********/
    fork(authorizationSaga),
    // 公共模块
    fork(commonSaga),
    // 我的待办模块
    fork(myAgencySaga),
    // 工作台模块
    fork(workBrenchSaga),
  ])
}

import React from "react";
import { Responsive, WidthProvider } from "react-grid-layout";

const ResponsiveGridLayout = WidthProvider(Responsive);

/** 组件类型 */
export interface ComponentsType {
  /** key */
  key: string;
  /** node */
  component: React.ReactNode;
}

type ResizeHandle = "s" | "w" | "e" | "n" | "sw" | "nw" | "se" | "ne";

/** 布局类型 */
export interface GridLayoutType {
  /** 组件的key */
  i: string;
  /** 横坐标 */
  x: number;
  /** 纵坐标 */
  y: number;
  /** 宽度 */
  w: number;
  /** 高度 */
  h: number;
  /** 最小宽度 */
  minW?: number;
  /** 最大宽度 */
  maxW?: number;
  /** 最小高度 */
  minH?: number;
  /** 最大高度 */
  maxH?: number;
  /** 为真时，项不可拖拽和缩放 */
  static?: boolean;
  /** 是否可拖拽 */
  isDraggable?: boolean;
  /** 是否可缩放 */
  isResizable?: boolean;
  /** 设置重置大小的图标出现的位置，默认是在右下角 */
  resizeHandles?: ResizeHandle[] | undefined;
  /** 是否设置边界 */
  isBounded?: boolean;
}

interface PropsFromComponents {
  /** 高度 */
  rowHeight?: number;
  /** 组件 */
  components: ComponentsType[];
  /** 布局 */
  layout: GridLayoutType[];
  /** 修改布局 */
  setLayout: (params: any) => void;
  /** 是否可拖拽 */
  isDraggable: boolean;
  collapse?: boolean
}

/** 响应式可拖拽组件 */
const GridLayout = (props: PropsFromComponents) => {

  const { rowHeight, layout, components, setLayout, isDraggable, collapse = false } = props;
  
  // 无论用户怎么定义，【欢迎模块】固定不允许拖动
  const constrainLayout: GridLayoutType[] = [];
  layout.forEach((item: GridLayoutType) => {
    if(item.i === "welcome") {
      const constrainItem: GridLayoutType = {...item, h: 1.8, static: true };
      constrainLayout.push(constrainItem)
    } else {
      constrainLayout.push(item)
    }
  })

  return (
    <ResponsiveGridLayout
      className="layout"
      style={{ width: "100%" }}
      layouts={{ lg: constrainLayout }}
      onLayoutChange={setLayout}
      margin={[20, 20]}
      rowHeight={rowHeight || 100}
      compactType="vertical"
      cols={{ lg: 12, md: 12, sm: 12, xs: 12, xxs: 12 }}
      isResizable={false}
      autoSize={true}
      isDraggable={isDraggable}
      key={`${collapse}`}
    >
      {components.map(item => <div key={item.key}>{item.component}</div>)}
    </ResponsiveGridLayout>
  )
}

export default GridLayout
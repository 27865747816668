import React from "react"
import { Button } from "antd"

interface LookButtonProps {
  record: any,
  menuList: any[],
  text?: string,
  style?: any,
  className?: string,
}

const LookButton: React.FC<LookButtonProps> = (props) => {
  const { record, menuList, text = "查看", style = {}, className = "" } = props

  const onGoDetail = () => {
    let menuItem: any = null
    menuList.map((item: any) => {
      if (item.appCode === record.appId && item.children?.length) {
        menuItem = item.children.find((val: any) => val.functionCode === record.functionCode)
      }
    })
    if (menuItem) {
      window.history.pushState({ paramInfo: record.paramInfo ? JSON.parse(record.paramInfo) : null }, "", menuItem.visitURL)
    }
  }
  return <Button type="link" style={style} className={className} onClick={onGoDetail}>{text}</Button>
}

export default LookButton
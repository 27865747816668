import { Reducer } from "redux";
import { CommonState, CommonActionTypes } from "./types";

// 初始化state
export const initCommonState: CommonState = {
  loading: {
    showA: false,
    showB: false,
    showC: false,
    text: "",
    second: 0
  },
  exportExcel: {
    showRate: false,
    rate: 0
  },
  openKeys: [],
  collapse: false,
};

const commonReducer: Reducer<CommonState> = (state = initCommonState, action) => {
  switch (action.type) {
    // loading
    case CommonActionTypes.FETCH_LOADING: {
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload
        }
      }
    }
    // 根据taskId轮询导出excel
    case CommonActionTypes.FETCH_EXPORT_BY_TASKID_FINISH: {
      return {
        ...state,
        exportExcel: action.payload
      }
    }
    // 更新单个state
    case CommonActionTypes.FETCH_UPDATE_COMMON_STATE: {
      return {
        ...state,
        ...action.payload
      }
    }
    default: {
      return { ...state };
    }
  }
}

export { commonReducer }

import { MyAgencyType } from "./types";

// 更新单个state
export const fetchUpdateMyAgencyState = (data: any) => ({
  type: MyAgencyType.FETCH_UPDATE_MYAGENCY_STATE,
  payload: data
})

// 获取我的待办列表数据
export const fetchGetMyAgencyList = (params: any) => ({
  type: MyAgencyType.FETCH_GET_MYAGENCYLIST,
  payload: params,
})

// 删除待办数据
export const fetchDeleteMyAgencyData = (params: any, callback: () => void) => ({
  type: MyAgencyType.FETCH_DELETE_MYAGENCYDATA,
  payload: {params, callback},
})

// 将待办数据标记为已完成
export const fetchCompleteMyAgencyData = (params: any, callback: any) => ({
  type: MyAgencyType.FETCH_COMPLETE_MYAGENCYDATA,
  payload: {params, callback},
})

import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Form, Select, Button, Upload, message, Spin, Tooltip, Popconfirm, Col } from "antd"
import { connect } from "react-redux";
import { FormComponentProps } from "antd/lib/form";
import { RouteComponentProps } from "react-router-dom"
import { ApplicationState } from "store";
import { Dispatch } from "redux";
import VhFormItem, { NodeType, ContentType } from "components/ms-common/vh-formItem"
import { TOKEN, CID, getPreRouterUrl } from "common/constants"
import moment from "moment";
import VhTable from "components/ms-common/vh-table/index"
import { fetchLoading } from "store/common/actions"
import { LoadingType } from "store/common/types"
import { fetchGetMyAgencyList, fetchDeleteMyAgencyData, fetchUpdateMyAgencyState, fetchCompleteMyAgencyData } from "store/my-agency/actions"
import { PageDataType, AgencyListType } from "store/my-agency/types"
import "./Workbench.scss"
import { IMapType } from "common/types"
import Tools from "utils/tools"
import { VhMenu } from "msd/dist/vh-sider";
import LookButton from "./LookButton"


const mapStateToProps = ({
  commonState,
  authorizationState,
  myAgencyState
}: ApplicationState) => ({
  loading: commonState.loading,
  // areaTypeData: authorizationState.commonMap.filter((item: any) => item.dataType === "客户大区"),
  pageData: myAgencyState.pageData,
  agencyList: myAgencyState.agencyList,
  todoTypeList: authorizationState.commonNewMap["coss.todoType"] || [],
  todoStatusList: authorizationState.commonNewMap["coss.todoStatus"] || [],
  menuList: authorizationState.menuList || []
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateLoading: (params: any) => dispatch(fetchLoading(params)),
  updateMyAgencyState: (params: any) => dispatch(fetchUpdateMyAgencyState(params)),
  getMyAgencyList: (params: any) => dispatch(fetchGetMyAgencyList(params)),
  deleteMyAgencyData: (params: any, callback: () => void) => dispatch(fetchDeleteMyAgencyData(params, callback)),
  completeMyAgencyData: (params: any, callback: () => void) => dispatch(fetchCompleteMyAgencyData(params, callback)),
});

interface MsPayslipAddProps {
  /** 左侧菜单数据 */
  menuList: VhMenu[]
  /**事项类型字典数据 */
  todoTypeList: IMapType[]
  /**待办状态字典数据 */
  todoStatusList: IMapType[]
  /**页面loading状态 */
  loading: LoadingType;
  /**客户来源数据（大区、企业） */
  areaTypeData: any[]
  /** 我的待办列表数据 */
  agencyList: AgencyListType
  /** 我的待办列表的分页数据 */
  pageData: PageDataType
  /**更新commonState的loading数据 */
  updateLoading: typeof fetchLoading;
  /**更新myAgency的state数据 */
  updateMyAgencyState: typeof fetchUpdateMyAgencyState
  /**获取我的待办列表数据 */
  getMyAgencyList: typeof fetchGetMyAgencyList
  /**删除待办数据 */
  deleteMyAgencyData: typeof fetchDeleteMyAgencyData
  /**将待办数据标记为已完成 */
  completeMyAgencyData: typeof fetchCompleteMyAgencyData
}


const MsPayslipAdd: React.FC<MsPayslipAddProps & FormComponentProps & RouteComponentProps> = (props) => {

  const [agencyData, setAgencyData] = useState<any[]>([])

  const {
    updateLoading,
    history,
    loading,
    form,
    pageData,
    agencyList,
    getMyAgencyList,
    deleteMyAgencyData,
    completeMyAgencyData,
    todoStatusList,
    todoTypeList,
    menuList
  } = props

  const onSearch = (data?: any) => {
    const formValue = form.getFieldsValue()
    const params: any = {
      ...formValue,
      startDate: formValue.createDate?.length > 0 ? moment(formValue.createDate[0]).format("YYYY-MM-DD") : "",
      endDate: formValue.createDate?.length > 0 ? moment(formValue.createDate[1]).format("YYYY-MM-DD") : "",
      modifyStartDate: formValue.modifyDate?.length > 0 ? moment(formValue.modifyDate[0]).format("YYYY-MM-DD") : "",
      modifyEndDate: formValue.modifyDate?.length > 0 ? moment(formValue.modifyDate[1]).format("YYYY-MM-DD") : "",
      pageIndex: data?.pageIndex || pageData.pageIndex || 1,
      pageSize: data?.pageSize || pageData.pageSize || 25,
      todoStatus: formValue.todoStatus ?? "",
      cid: CID
    }
    delete params.createDate
    delete params.modifyDate
    getMyAgencyList(params)
  }

  useEffect(() => {
    onSearch()
  }, [])

  useEffect(() => {
    const functionCodeList: string[] = []
    menuList.map((item: any) => {
      if (item.children && item.children.length) {
        item.children.forEach((val: any) => {
          functionCodeList.push(val.functionCode)
        })
      } else {
        functionCodeList.push(item.functionCode)
      }
    })
    const res = agencyList.filter((item: any) => !!functionCodeList.find((val: any) => val === item.functionCode))
    setAgencyData(res)
  }, [menuList, agencyList])

  const onPageIndexChange = (current: number, size: number) => {
    onSearch({ pageIndex: current })
  }

  const onPageSizeChange = (current: number, size: number) => {
    onSearch({ pageIndex: 1, pageSize: size })
  }

  const onComplete = (record: any) => {
    const params = {
      id: record.id,
      cid: CID,
      todoStatus: 1
    }
    completeMyAgencyData(params, onSearch)
  }

  const onDelete = (record: any) => {
    const params = {
      id: record.id,
      isDel: 1,
      cid: CID
    }
    deleteMyAgencyData(params, onSearch)
  }

  const content: ContentType[] = [
    { type: NodeType.rangePicker, key: "createDate", label: "创建时间", colProps: { span: 6 }, eleProps: { style: { width: "100%" } } },
    { type: NodeType.rangePicker, key: "modifyDate", label: "截止时间", colProps: { span: 6 }, eleProps: { style: { width: "100%" } } },
    { type: NodeType.input, key: "todoDesc", label: "代办内容", colProps: { span: 5 }, eleProps: { style: { width: "100%" }, placeholder: "请输入待办内容查询" } },
    { type: NodeType.select, key: "todoStatus", label: "状态", colProps: { span: 4 }, options: todoStatusList, formItemProps: { className: "statuItem" }, eleProps: { style: { width: "100%" }, placeholder: "请选择状态查询", allowClear: true } },
  ]

  const tableColumns: any[] = [
    { title: "创建时间", align: "left", dataIndex: "createDate", width: 174, key: "createDate", render: (text: any) => text || "--" },
    { title: "截止时间", align: "left", dataIndex: "endDate", width: 174, key: "endDate", render: (text: any) => text || "--" },
    { title: "事项类型", align: "left", dataIndex: "todoType", width: 110, key: "todoType", render: (text: any) => Tools.mappingFilter(todoTypeList, `${text}`) || "--" },
    // { title: "代办事项", align: "left", dataIndex: "agencyContent", key: "agencyContent", width: 324, render: (text: any) => <Tooltip title={text}><span className="whiteSpace">{text || "--"}</span></Tooltip> },
    { title: "代办事项", align: "left", width: 360, dataIndex: "todoDesc", key: "todoDesc", render: (text: any) => text || "--" },
    {
      title: "剩余时长", align: "left", dataIndex: "remainingTime", width: 140, key: "remainingTime", render: (text: any, record: any) => {
        // 已完成或者值不存在的时候，显示--
        if((!text && text !== 0) || record.todoStatus === 1) {
          return "--"
        }
        // const time = Math.abs(text)
        // const days = Math.floor(time / 24)
        // const hours = time % 24
        // return <span className="error-color">{`${text < 0 ? "已超时" : "还剩"}${days}天${hours}小时`}</span>
        return <span className="error-color">{`${text < 0 ? "已超时" : "还剩"}${Math.abs(text)}小时`}</span>
      }
    },
    { title: "当前状态", align: "left", dataIndex: "todoStatus", width: 110, key: "todoStatus", render: (text: any) => Tools.mappingFilter(todoStatusList, `${text}`) || "--" },
    { title: "完成时间", align: "left", dataIndex: "completeDate", width: 160, key: "completeDate", render: (text: any) => text || "--" },
    {
      title: "操作", dataIndex: "opt", align: "left", width: 170, key: "opt", render: (_: any, record: any) => (
        <div>
          {record.todoStatus !== 1 && <Popconfirm title="是否确定标记已完成？" onConfirm={() => onComplete(record)}>
            <Button type="link" style={{marginRight: 10}}>标记已完成</Button>
          </Popconfirm>}
          <LookButton style={{ marginRight: 10 }} record={record} menuList={menuList} />
          <Popconfirm title="是否确定删除？" onConfirm={() => onDelete(record)}>
            <Button type="link">删除</Button>
          </Popconfirm>
        </div>
      )
    },
  ]

  const pagination = {
    pageSize: pageData.pageSize,
    current: pageData.pageIndex,
    pageSizeOptions: ["25", "50", "100"],
    showQuickJumper: true,
    showSizeChanger: true,
    total: pageData.total,
    showTotal: (total: number) => `共${total}条`,
    onChange: onPageIndexChange,
    onShowSizeChange: onPageSizeChange,

  }
  const scrollWidth = tableColumns.reduce((res: number, item: any) => res += item.width || 160, 0)

  return (
    <div className="myAgency">
      <Spin spinning={loading.showB}>
        <Form className="agencyForm" layout="inline" autoComplete="off" style={{ overflow: "hidden", margin: "10px 0" }}>
          <VhFormItem content={content} form={form} />
          <Col span={3}>
            <Form.Item style={{ marginLeft: 20 }}>
              <Button type="primary" style={{ marginRight: 50 }} onClick={onSearch}>查询</Button>
            </Form.Item>
          </Col>
        </Form>
        <VhTable
          key="SalaryMonth"
          // rowKey={(record: any) => `${record.cid}${record.salaryMonth}${record.salaryGroupID}`}
          bordered={false}
          dataSource={agencyData}
          columns={tableColumns}
          scroll={{ x: scrollWidth }}
          pagination={pagination}
          otherHeight={110}
        />
      </Spin>
    </div>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(
  Form.create<MsPayslipAddProps & FormComponentProps & RouteComponentProps>()(MsPayslipAdd)
)
import { WorkBenchType } from "./types";

// 更新单个state
export const fetchUpdateWorkbenchState = (data: any) => ({
  type: WorkBenchType.FETCH_UPDATE_WORKBENCH_STATE,
  payload: data
})

// 服务看板分页
export const fetchProgressBoardPageList = (params: any) => ({
  type: WorkBenchType.FETCH_PROGRESS_BOARD_LIST,
  payload: params
})

// 查询服务看板明细
export const fetchProgressBoardLog = (params: any) => ({
  type: WorkBenchType.FETCH_PROGRESS_BOARD_LOG_LIST,
  payload: params
})

// 消息分页
export const fetchMessageList = (params: any) => ({
  type: WorkBenchType.FETCH_MESSAGE_LIST,
  payload: params
})

// 更新消息状态
export const fetchUpdateMessageStatus = (params: any) => ({
  type: WorkBenchType.FETCH_UPDATE_MESSAGE_STATUS,
  payload: params
})

// 获取未读消息统计
export const fetchMessageCount = () => ({
  type: WorkBenchType.FETCH_MESSAGE_COUNT
})

// 获取未读消息统计
export const fetchUnReadMessageList = (params: any) => ({
  type: WorkBenchType.FETCH_UNREAD_MESSAGE_LIST,
  payload: params
})

// 收入发放本月统计
export const fetchSalaryPayMonthStatistics = (params: any) => ({
  type: WorkBenchType.FETCH_SALARY_PAY_MONTH_STATISTICS,
  payload: params
})

// 工作台更新
export const fetchUpdateWorkbenchInfo = (params: any) => ({
  type: WorkBenchType.FETCH_UPDATE_WORK_BENCH_INFO,
  payload: params
})

// 服务看板-数据统计
export const fetchProgressBoardAllCount = () => ({
  type: WorkBenchType.FETCH_PROGRESS_BOARD_ALL_COUNT
})

// 服务看板-获取责任方数据
export const fetchDistinctCossServiceProgressList = (params: any) => ({
  type: WorkBenchType.FETCH_DISTINCT_COSS_SERVICE_PROGRESS_LIST,
  payload: params
})

// 登录人信息及页面布局
export const fetchUserInfoAndConfigList = () => ({
  type: WorkBenchType.FETCH_USER_INFO_AND_CONFIG_LIST
})

// 获取我的待办和统计数据
export const fetchGetAgencyData = (params: any) => ({
  type: WorkBenchType.FETCH_GET_AGENCY_LIST_AND_STATIC,
  payload: params
})
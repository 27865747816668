import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Card, Button, List, Row, Tooltip, Spin, Typography, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { fetchMessageList, fetchUnReadMessageList, fetchUpdateMessageStatus, fetchUpdateWorkbenchState } from "store/workbrench/actions";
import { MessageListType } from "store/workbrench/types";
import { CID, TDAPP } from "common/constants";
import { VhTools } from "msd";
import "./VhNewMessage.scss";
import moment from "moment";
import { ICompany, IUser } from "common/types";

const mapState = ({ commonState, workBenchState, authorizationState }: ApplicationState) => ({
  /** 加载 */
  loading: commonState.loading,
  /** 消息列表 */
  messageList: workBenchState.messageList,
  /** 未读消息列表 */
  unReadMessageList: workBenchState.unReadMessageList,
  /** 消息类型数据字典 */
  messageTypeData: authorizationState.commonNewMap["coss.messageType"],
  /** 菜单列表 */
  menuList: authorizationState.menuList || [],
  userAndCompany: authorizationState.userInfo,
  companyInfo: authorizationState.companyInfo
})

interface PropsFromComponent {
  /** 未读模式 */
  unReadMode?: boolean;
  from?: string;
}


type AllProps = PropsFromComponent & RouteComponentProps

/** 工作台-消息 */
const VhNewMessage = (props: AllProps) => {

  const dispatch = useDispatch()

  const { loading, messageList, unReadMessageList, messageTypeData, menuList, userAndCompany:{ realName }, companyInfo: { name } } = useSelector(mapState)

  const { history, unReadMode } = props

  const [newMessageList, setNewMessageList] = useState<MessageListType[]>([])

  /** 查询消息列表 */
  useEffect(() => {
    if (!unReadMode) {
      dispatch(fetchMessageList({
        pageIndex: 1,
        pageSize: 30,
        cid: CID
      }))
    } else {
      dispatch(fetchUnReadMessageList({
        messageStatus: 0,
        cid: CID,
        pageIndex: 1,
        pageSize: 9999
      }))
    }
  }, [unReadMode])

  useEffect(() => {
    setNewMessageList(unReadMode ? unReadMessageList : messageList)
  }, [unReadMode, messageList, unReadMessageList])

  /** 查看 */
  const onCheck = (record: any) => {
    let menuItem: any = null
    menuList.map((item: any) => {
      if (item.appCode === record.appId && item.children?.length) {
        menuItem = item.children.find((val: any) => val.functionCode === record.functionCode)
      }
    })
    if (menuItem) {
      window.history.pushState({ paramInfo: record.paramInfo ? JSON.parse(record.paramInfo) : null }, "", menuItem.visitURL + `/${record.id}`)
    }
  }

  /** 查看消息 */
  const onCheckMessage = async (record: MessageListType) => {
    // 未读消息更新成已读
    if (+record.messageStatus === 0) {
      dispatch(fetchUpdateMessageStatus({
        params: {
          messageStatus: 1,
          id: record.id,
          cid: CID
        },
        callback: () => {
          if (unReadMode) {
            const newUnReadMessageList = newMessageList.filter((item: MessageListType) => item.id !== record.id)
            setNewMessageList([...newUnReadMessageList])
            dispatch(fetchUpdateWorkbenchState({
              unReadMessageList: [...newUnReadMessageList],
              unReadMessageCount: newUnReadMessageList.length
            }))
          }
        }
      }))
    }
    if (+record.messageType === 3) {
      Modal.info({
        title: "系统公告",
        content: (
          <div>
            <p>{record.messageDesc}</p>
          </div>
        )
      })
    } else {
      onCheck(record)
    }
  }

  const handleOnMouseEnter = (module: string) => {
      const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
      if(props.from) {
        TDAPP.onEvent("进入工作台模块",`${name}_${realName}`,{"用户名":`${realName}_${timestamp}`,"公司" : `${name}_${timestamp}`,"模块": `${module}_${timestamp}`})
      }
    }

    const handleOnMouseLeave = (module: string) => {
      const timestamp = moment().format("YYYY-MM-DD HH:mm:ss")
      if(props.from) {
        TDAPP.onEvent("离开工作台模块",`${name}_${realName}`,{"用户名":`${realName}_${timestamp}`,"公司" : `${name}_${timestamp}`,"模块": `${module}_${timestamp}`})
      }
    }

  return (
    <Card
      onMouseEnter={ handleOnMouseEnter.bind(VhNewMessage,"消息") } onMouseLeave = { handleOnMouseLeave.bind(VhNewMessage,"消息") }
      className="new-message-card"
      bordered={false}
      title={(
        <div>
          <span className="title">消息</span>
          <span className="subtitle">{unReadMode ? "查看最近的未读消息" : "仅显示最近约30条记录"}</span>
        </div>
      )}
      extra={newMessageList.length > 0 && (
        <Button type="link" onClick={() => history.push("/workbench/new-message")}>查看全部</Button>
      )}
    >
      <Spin spinning={loading.showA} tip={loading.text}>
        <List
          className="message-list"
          bordered={false}
          dataSource={newMessageList}
          rowKey="id"
          renderItem={(message: MessageListType) => (
            <List.Item key={message.id}>
              <Row type="flex" justify="space-between" style={{ width: "100%" }}>
                <div style={{ width: "calc(100% - 62px)" }}>
                  <Row type="flex">
                    <div className="tag">
                      <Typography.Text>
                        [{message.messageType ? VhTools.mappingFilter(messageTypeData, message.messageType.toString()) : "--"}]
                      </Typography.Text>
                    </div>
                    <Tooltip title={message.messageDesc}>
                      <div className="title">{message.messageDesc}</div>
                    </Tooltip>
                  </Row>
                  <div className="subtitle">{message.createDate}</div>
                </div>
                <Button type="link" onClick={() => onCheckMessage(message)}>查看</Button>
              </Row>
            </List.Item>
          )}
        />
      </Spin>
    </Card>
  )
}

export default withRouter(VhNewMessage)
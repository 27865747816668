import { LOGIN_URL, ERROR_MESSAGE, TOKEN, CID, SOURCE_APPCODE } from "common/constants";
import Tools from "./tools";
import { getNodeByString } from "./getHtml";
// import Modal from "antd/lib/modal/Modal";
import { message } from "antd";
import { VhModal } from "msd";

let isShowLoginModal = false

export default function fetchApi(method: string, url: string, data?: any) {
  let hideError = false;


  const extenalCid = Tools.getUrlParam("mscid");
  const extenalTOKEN = Tools.getUrlParam("token");
  const sourceAppName = Tools.getUrlParam("sourceAppName");
  const urlSearch: any[] = window.location.search.split("&");
  if (extenalCid) {
    console.log("引用外部CID", extenalCid)
  } else {
    console.log("未引用外部CID")
  }

  // 当前访问来自【外部地址引用】且 传入鉴权数据，则使用外部鉴权数据
  const token = extenalTOKEN && extenalTOKEN !== "" ? extenalTOKEN : TOKEN;
  const cId = extenalCid && extenalCid !== "" ? extenalCid : CID;

  const resultMock = {
    result: 0,
    detail: "",
    data: {
      records: []
    }
  };

  const path = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ""}`;

  // 是否不处理error
  if (data && data.hideError === true) {
    hideError = true;
    delete data.hideError;
  }

  // header设置
  const headers: any = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    "Pragma": "no-cache",
    "Authorization": "Bearer " + token,
    "Mscid": cId
  };

  // CID和token check
  if (!token) {
    delete headers.Authorization;
  }
  if (!cId) {
    delete headers.Mscid;
  }

  // debugger;
  // return resultMock;
  // 发出请求
  return fetch(path + "/" + url, {
    method,
    headers: { ...headers },
    body: ["post", "delete"].includes(method) && data ? JSON.stringify(data) : undefined
  })
    .then(res => {
      if(`${res.status}`.startsWith("5")) {
        message.destroy()
        message.error(ERROR_MESSAGE)
        return Promise.reject(new Error(ERROR_MESSAGE))
      }
      return res.json()
    })
    .then(value => {
      if (value.result === 401) {
        !isShowLoginModal && VhModal.error({
          content: "登陆已过期，请重新登陆！",
          onOk: () => {
            isShowLoginModal = false
            if (sourceAppName === "xiangmayun") {
              window.parent.postMessage("fetchCheckFail", "*");
            } else {
              Tools.delCookie("ms_member_token");
              Tools.delCookie("ms_cid");
              SOURCE_APPCODE
                ? (window.parent.location.href = LOGIN_URL)
                : (window.location.href = LOGIN_URL);
            }
          }
        })
        isShowLoginModal = true
      }
      else if (value.result !== 0 && !hideError) {
        VhModal.error({
          content: getNodeByString(value.detail || ERROR_MESSAGE),
          onOk: () => {
            if (value.result === 402) {
              if (sourceAppName === "xiangmayun") {
                window.parent.postMessage("fetchCheckFail", "*");
              } else {
                Tools.delCookie("ms_member_token");
                Tools.delCookie("ms_cid");
                SOURCE_APPCODE
                  ? (window.parent.location.href = LOGIN_URL)
                  : (window.location.href = LOGIN_URL);
              }
            }
          }
        });
        if(value.result === 1002) {
          return value
        }
      }
      else {
        return value;
      }
    })
    .catch(err => {
      console.log("error", err)
    })
}


/**
 * 文件升级接口、下载附件接口调用的请求，
 * 两个接口返回的都是文件流，根据返回的响应头部内容进行判断，
 * 逻辑：
 * 文件升级接口根据content-type判断:  没有值：成功。 有值：错误。
 * 下载附件接口根据content-disposition判断： 没有值：错误。 有值：成功，并从中获取文件名。 
 * @param method  请求方式
 * @param url     地址
 * @param data    入参
 */
 export function fetchFileApi(method: string, url: string, data?: any) {
  const token = TOKEN;
  const cId = CID;
  let respHeader = "content-type";
  let fn: any = null;

  // 断网情况check
  if (!navigator.onLine) {
    VhModal.error({content: "网络原因，请检查网络后继续！"});
    return;
  }

  // header设置
  const headers: {[key: string]: string} = {
    "Cache-Control": "no-cache",
    "Pragma": "no-cache",
    "Authorization": "Bearer " + token,
    "Mscid": cId
  };

  // 检查token和cid
  if (!token) {
    delete headers.Authorization;
  }
  if (!cId) {
    delete headers.Mscid;
  }

  // 去除fn方法，文件升级接口不传fn，不会进入此方法；下载附件会进入此方法，最终data为{}
  if (data.fn) {
    fn = data.fn;
    respHeader = "content-disposition";
    delete data.fn;
  }

  // 发出请求，文件升级为post方式，下载附件为get方式。
  return fetch("/" + url, {
    method,
    headers: {...headers},
    body: method === "post" ? data : undefined
  })
  .then(res => {
    const str: string | null = res.headers.get(respHeader);
    if (fn) {
      if (str) {
        fn(str.split(";")[1].split("=")[1]);
        return res.blob();
      } else {
        return res.json();
      }
    }
    if (!str) {
      return res.blob();
    }
    return res.json();
  })
  .then(value => {
    if (value.detail) {
      VhModal.error({content: value.detail});
    } else {
      return value;
    }   
  })
}
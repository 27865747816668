import { createAsyncAction } from "typesafe-actions";
import { AuthorizationActionTypes } from "./types";

/**获取登陆用户信息 */
export const fetchUserInfo = createAsyncAction(
  AuthorizationActionTypes.FETCH_USER_INFO,
  AuthorizationActionTypes.FETCH_USER_INFO_SUCCESS,
  AuthorizationActionTypes.FETCH_USER_INFO_ERROR
)<void, any, string>();

/**获取应用列表 */
export const fetchAppList = createAsyncAction(
  AuthorizationActionTypes.FETCH_APP_LIST,
  AuthorizationActionTypes.FETCH_APP_LIST_SUCCESS,
  AuthorizationActionTypes.FETCH_APP_LIST_ERROR
)<void, any, string>();

/**获取应用列表（右上角下拉数据） */
export const fetchConfigAppList = createAsyncAction(
  AuthorizationActionTypes.FETCH_CONFIG_APP_LIST,
  AuthorizationActionTypes.FETCH_CONFIG_APP_LIST_SUCCESS,
  AuthorizationActionTypes.FETCH_CONFIG_APP_LIST_ERROR
)<void, any, string>();

/**获取菜单列表 */
export const fetchMenuList = createAsyncAction(
  AuthorizationActionTypes.FETCH_MENU_LIST,
  AuthorizationActionTypes.FETCH_MENU_LIST_SUCCESS,
  AuthorizationActionTypes.FETCH_MENU_LIST_ERROR
)<any, any, string>();

/**获取公共字典(新版) */
export const fetchCommonNewMap = createAsyncAction(
  AuthorizationActionTypes.FETCH_COMMON_NEW_MAP,
  AuthorizationActionTypes.FETCH_COMMON_NEW_MAP_SUCCESS,
  AuthorizationActionTypes.FETCH_COMMON_NEW_MAP_ERROR
)<void, any, string>();

/**登出 */
export const fetchLogout = createAsyncAction(
  AuthorizationActionTypes.FETCH_LOGOUT,
  AuthorizationActionTypes.FETCH_LOGOUT_SUCCESS,
  AuthorizationActionTypes.FETCH_LOGOUT_ERROR
)<void, any, string>();

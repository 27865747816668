import { put, takeEvery, call, delay, takeLatest } from "redux-saga/effects";
import { ERROR_MESSAGE } from "common/constants";
import { CommonActionTypes } from "./types";
import {
  fetchLoading,
  fetchExportByTaskId,
  fetchExportExcel,
  fetchExportByTaskIdFinish,
} from "./actions";
import  { VhModal } from "msd";
import fetchApi from "utils/fetchApi";
import { VhTools } from "msd";

// 导出获取taskId
function* handleGetTaskId(action: ReturnType<typeof fetchExportExcel>) {
  try {
    yield put(fetchLoading({showA: true, text: "正在准备导出，请稍后..."}));
    const { url, taskIdUrl, fileName, method, params, succ } = action.payload;
    let res;
    console.log(999, url, params)
    if(!method){
      res = yield call(fetchApi, "get", url);
    } else {
      
      res = yield call(fetchApi, "post", url,{...params});
    }
    if (res) {
      succ();
      yield put(fetchExportByTaskId({
        ...res.data, 
        fileName,
        taskIdUrl,
        method
      }));
    }
  } catch (err) {
    VhModal.error({content: err instanceof Error ? err.stack! : ERROR_MESSAGE});
  }
  yield put(fetchLoading({showA: false, text: ""}));
}

/**
 * 执行导出，结束后延时1秒是为了显示100%进度。
 * stopDownload： 页面点“取消”时传的参数，停止执行
 */
function* handleGetExportRate(action: ReturnType<typeof fetchExportByTaskId>) {
  const { taskId, fileName, taskIdUrl, stopDownload, method } = action.payload;
  try {
    if (stopDownload) {
      yield put(fetchExportByTaskIdFinish({showRate: false, rate: 0}));
    } else {
      const p = method ? `${taskIdUrl}?taskId=${taskId}` : VhTools.appendParams(taskIdUrl, {taskId, fileName});
      const res = yield call(fetchApi, "post", p);
      if (res) {
        if (res.data.rate < 100) {
          yield delay(300);
          yield put(fetchExportByTaskIdFinish({showRate: true, rate: res.data.rate}));
          yield put(fetchExportByTaskId(action.payload));
        } else {
          yield put(fetchExportByTaskIdFinish({showRate: true, rate: res.data.rate}));
          yield delay(1000);
          yield put(fetchExportByTaskIdFinish({showRate: false, rate: 0}));
          yield VhTools.downloadFile(res.data.downloadPath, `${fileName}.xlsx`);
        }
      } else {
        yield put(fetchExportByTaskIdFinish({showRate: false, rate: 0}));
      }
    }
  } catch (err) {
    yield put(fetchExportByTaskIdFinish({showRate: false, rate: 0}));
    VhModal.error({content: err instanceof Error ? err.stack! : ERROR_MESSAGE});
  }
}

// 监控，根据指定action类型，调用saga方法
function* commonSaga() {
  yield takeEvery(CommonActionTypes.FETCH_EXPORT_EXCEL, handleGetTaskId);
  yield takeLatest(CommonActionTypes.FETCH_EXPORT_BY_TASKID, handleGetExportRate);
}

export default commonSaga;
